import { MealCategory, IMealCategory } from "Domain/Model/MealCategory/MealCategory";
import { IMealSelection, MealSelection, IMealSelectionOption } from "Domain/Model/MealSelection/MealSelection";
import { IIngredient, Ingredient } from "Domain/Model//Ingredient/Ingredient";
import { BaseModel } from "Domain/Model//BaseModel/BaseModel";

export interface IMeal {
    id:          string,
    number:      string,
    name:        string,
    description: string,
    price:       string,
    ingredients?:     IIngredient[];
    category?:        IMealCategory|{},
    selections?:      IMealSelection[],
    selectionOptions: Record<string, IMealSelectionOption>,
}

export class Meal extends BaseModel {

    public id:          string;
    public number:      string;
    public iNumber:     number;
    public name:        string;
    public description: string;
    public price:       number;
    public category?:   MealCategory|any;
    public ingredients: Ingredient[];
    public selections?: MealSelection[];
    public selectionOptions: Record<string, IMealSelectionOption>;

    constructor(data: IMeal) {
        super();

        this.id          = data.id;
        this.number      = data.number;
        this.name        = data.name;
        this.description = data.description;
        this.price       = parseFloat(data.price);

        const match = `${this.number}`.match(/\d+/g);
        this.iNumber = (!!match) ? parseInt(match[0]) : 0;

        this.category         = data.category ? new MealCategory(data.category as IMealCategory) : {};
        this.selections       = (data.selections) ? data.selections.map(entry => new MealSelection(entry)) : [];
        this.ingredients      = (data.ingredients) ? data.ingredients.map((entry: IIngredient) => new Ingredient(entry)) : [];
        this.selectionOptions = data.selectionOptions;
    }

}