import { IReduxStore } from "Redux/Store/IReduxStore";
import { filterObjectStore, queryFilter, IFilterProps } from 'Redux/Query';
import { MealCategory } from "Domain/Model/MealCategory/MealCategory";

export const getMealCategoriesBySearch = (state: IReduxStore, filter: null|IFilterProps<MealCategory> = null) => {
    if(filter === null) {
        filter = queryFilter({sorting: { by: 'name', order: 'ASC' }}) as IFilterProps<MealCategory>;
    }

    return filterObjectStore(state.mealCategory.searchEntries, filter);
};
