import * as Global       from "./Global";
import * as Notification from "./Notification";
import * as Basket       from "./Basket";
import * as User         from "./User";
import * as Allergen     from "./Allergen";
import * as Ingredient   from "./Ingredient";
import * as MealCategory from "./MealCategory";
import * as Meal         from "./Meal";
import * as Reduction    from "./Reduction";
import * as Application  from "./Application";
import * as Order        from "./Order";

export * from "./Global";
export * from "./Notification";
export * from "./Basket";
export * from "./User";
export * from "./Allergen";
export * from "./Ingredient";
export * from "./MealCategory";
export * from "./Meal";
export * from "./Reduction";
export * from "./Application";
export * from "./Order";


const bundledActions = {
    ...Global,
    ...Notification,
    ...Basket,
    ...User,
    ...Allergen,
    ...Ingredient,
    ...MealCategory,
    ...Meal,
    ...Reduction,
    ...Application,
    ...Order,
};

const errorRegEx = RegExp('FAILURE');
export const errorActionConstants = Object.keys(bundledActions)
    .filter(actionConstant => errorRegEx.test(actionConstant));
