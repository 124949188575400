import styled from "styled-components";

export default styled.div `

    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(100, 100, 100, 0.4);
    opacity: 0;
    transition: opacity 0.3s;
    
    &.show {
        transition: opacity 0.5s;
        opacity: 1;
    }
    
    .contentContainer {
        position: absolute;
        top: 40px;
        left: 40px;
        width: calc(100% - 80px);
        height: calc(100% - 80px);
        background-color: white;
        overflow: auto;
        border-radius: 2px;
        
        .contentWrapper {
            padding: 25px;
            min-height: calc(100% - 80px);
            height: calc(100% - 80px);
            overflow: auto;
        }
        
        &.hasControls {
            
            .contentWrapper {
                min-height: calc(100% - 160px);
                height: calc(100% - 160px);
            }
            
            .controls {
                margin-top: 20px;
                height: 60px;
                padding: 0 40px;
                font-size: 24px;
                line-height: 40px;
                position: relative;
                text-align: right;
                
                &::after {
                    content: '';
                    position: absolute;
                    height: 1px;
                    background-color: rgba(0,0,0,0.65);
                    width: calc(100% - 36px);
                    top: -10px;
                    left: 18px;
                }
            }
            
        }
        
        .contentHeadline {
            height: 80px;
            padding: 20px 40px;
            font-size: 24px;
            line-height: 40px;
            position: relative;
            
            &::after {
                content: '';
                position: absolute;
                height: 1px;
                background-color: rgba(0,0,0,0.65);
                width: calc(100% - 36px);
                bottom: 7px;
                left: 18px;
            }
        }

        .closePopOver {
            font-size: 24px;
            position: absolute;
            right: 25px;
            top: 25px;
        }
        
    }


    @media (max-width: 680px) {

        .contentContainer {

            .closePopOver {
                top: 20px;
            }

            .contentHeadline {
                padding: 20px 60px 20px 20px;
                height: auto;
                line-height: 22px;
            }
        }
        
        h2 {
            font-size: 20px;
        }

    }
`;