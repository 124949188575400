import { BaseModel } from "Domain/Model//BaseModel/BaseModel";
import { Basket, IBasket } from "Domain/Model/BasketEntry/BasketEntry";


export type TPaymentMethod = 'PayPal'|'Cash';
export type TShippingMethod = 'PickUp'|'Deliver';

export type TOrderState        = "OPEN_ORDER"|"ACCEPT"|"CANCELED"|"FINISHED"|"FAILED";
export type TOrderPaymentState = "OPEN"|"PAID"|"CANCELED";

export interface IOrderData {
    address:     string,
    lat:         number,
    lng:         number,
    basket:      IBasket[],
    mail:        string,
    phone:       string,
    comment:     string,
    name:        string,
    company:     string,
    total:       number,
    state?:      TOrderState,
    discount:    number,
    shippingFee: number,
    endPrice:    number,
    paymentState?:  TOrderPaymentState,
    paymentMethod:  TPaymentMethod,
    shippingMethod: TShippingMethod,
}

interface IOrderEntry {
    id: string,
    createdAt: number,
    invoiceNumber: string,
}

export type TOrder = IOrderData & IOrderEntry

export class Order extends BaseModel {

    public id:            string;
    public createdAt:     number;
    public address:       string;
    public lat:           number;
    public lng:           number;
    public basket:        Basket[];
    public comment:       string;
    public name:          string;
    public company:       string;
    public mail:          string;
    public phone:         string;
    public state?:        TOrderState;
    public total:         number;
    public endPrice:      number;
    public shippingFee:   number;
    public discount:      number;
    public invoiceNumber: string;
    public paymentMethod:  TPaymentMethod;
    public paymentState?:  TOrderPaymentState;
    public shippingMethod: TShippingMethod;

    constructor(data: TOrder) {
        super();

        this.id             = data.id;
        this.createdAt      = data.createdAt;
        this.address        = data.address;
        this.lat            = data.lat;
        this.lng            = data.lng;
        this.basket         = data.basket.map(basketEntry => new Basket(basketEntry));
        this.name           = data.name;
        this.comment        = data.comment;
        this.company        = data.company;
        this.total          = data.total;
        this.mail           = data.mail;
        this.state          = data.state;
        this.phone          = data.phone;
        this.endPrice       = data.endPrice;
        this.shippingFee    = data.shippingFee;
        this.discount       = data.discount;
        this.invoiceNumber  = data.invoiceNumber;
        this.paymentState   = data.paymentState;
        this.paymentMethod  = data.paymentMethod;
        this.shippingMethod = data.shippingMethod;
    }

}