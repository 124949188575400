import { Allergen } from "Domain/Model/Allergen/Allergen";
import { IMealSelectionOption } from "Domain/Model/MealSelection/MealSelection";
import {BaseModel} from "Domain/Model/BaseModel/BaseModel";

export interface IIngredient {
    id:          string,
    name:        string,
    description: string,
    allergens:   Allergen[],
    price?:      number,
    options?:    IMealSelectionOption[],
}

export class Ingredient extends BaseModel {

    public id:          string;
    public name:        string;
    public description: string;
    public allergens:   Allergen[];
    public price?:      number;
    public options?:    IMealSelectionOption[]

    constructor(data: IIngredient) {
        super();

        this.id          = data.id;
        this.name        = data.name;
        this.description = data.description;
        this.allergens   = data.allergens ? data.allergens.map(allergen => new Allergen(allergen)) : [];
        if(data.price) {
            this.price   = data.price;
        }
        if(data.options) {
            this.options = data.options;
        }


    }

}