import { IReduxStore } from "Redux/Store/IReduxStore";
import { filterObjectStore, queryFilter, IFilterProps } from 'Redux/Query';
import { Meal } from "Domain/Model/Meal/Meal";

export const getMeals = (state: IReduxStore, filter: null|IFilterProps<Meal> = null) => {
    if(filter === null) {
        filter = queryFilter({sorting: { by: 'iNumber', order: 'ASC' }}) as IFilterProps<Meal>;
    }

    return filterObjectStore(state.meal.meal.entries, filter);
};
