import { IFilterSettings } from "Domain/Repository/BaseRepository/BaseRepository";

export const FIND_REDUCTIONS_REQUEST = 'FIND_REDUCTIONS_REQUEST';

export const findReductionsRequest = ({
     filter = [],
     sorting = { field: 'id', order: 'ASC' },
     pagination = { page: 1, take: 50 },
 }: IFilterSettings ) => ({
    type: FIND_REDUCTIONS_REQUEST,
    payload: { filter, sorting, pagination },
    meta: {
        sorting,
    }
});
