import { BaseModel } from "Domain/Model/BaseModel/BaseModel";

export interface ISelectionEntry {
    id: string,
    optionId: string,
    price: number,
    label: string
}

export interface IExcludeIngredient {
    id: string,
    label: string,
}


export interface IToppingEntry {
    id: string,
    price: number,
    ingredientId: string,
    label: string
}

export interface IBasket {
    mealId:      string,
    categoryId:  string,
    name:        string,
    description: string,
    price:       number,
    totalPrice:  number,
    tax:         number,
    amount:      number,
    selections:  ISelectionEntry[],
    toppings:    IToppingEntry[],
    excludeIngredients: IExcludeIngredient[],
}

export class Basket extends BaseModel {

    public id:          string;
    public mealId:      string;
    public categoryId:  string;
    public name:        string;
    public description: string;
    public price:       number;
    public sorting:     number;
    public totalPrice:  number;
    public tax:         number;
    public amount:      number;
    public selections:  ISelectionEntry[]
    public toppings:    IToppingEntry[]
    public excludeIngredients: IExcludeIngredient[]

    constructor(data: IBasket) {
        super();

        this.mealId      = data.mealId;
        this.categoryId  = data.categoryId;
        this.name        = data.name;
        this.description = data.description;
        this.price       = data.price;
        this.totalPrice  = data.totalPrice;
        this.tax         = data.tax;
        this.amount      = data.amount;
        this.selections  = data.selections;
        this.toppings    = data.toppings;
        this.excludeIngredients = data.excludeIngredients;
        this.sorting     = 0;

        this.id          = this.getBasketId();

    }

    getBasketId = () => {
        let id = this.mealId;
        if(this.selections.length) {
            id += `s[${this.selections.map(selection => `${selection.id}|${selection.optionId}`).join('||')}]`;
        }
        if(this.toppings.length) {
            id += `t[${this.toppings.map(topping => `${topping.id}`).join('||')}]`;
        }
        id += this.description || '';

        return btoa(id);
    }

}