import { Order } from "Domain/Model/Order/Order";

export const FIND_ORDERS_SUCCESS = 'FIND_ORDERS_SUCCESS';

interface IFindOrdersSuccess {
    orders: Record<string, Order>,
    request: {},
    response: {},
}

export const findOrdersSuccess = ({ orders, request, response }: IFindOrdersSuccess) => ({
    type: FIND_ORDERS_SUCCESS,
    payload: { orders },
    meta: {
        request,
        response,
    }
});
