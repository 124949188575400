import {IReduxStore} from "Redux/Store/IReduxStore";
import { IMonthSalesEntry, getOrderSalesStructure } from "Redux/Query";

export const getTotalSalesData = (state: IReduxStore): IMonthSalesEntry[] => {
    const sales  = state.order.sales;
    const months = getOrderSalesStructure();

    sales.total.forEach((entry) => {
        if(months.hasOwnProperty(entry.ym)) { months[entry.ym].total = entry.total; }
    });
    sales.paypal.forEach((entry) => {
        if(months.hasOwnProperty(entry.ym)) { months[entry.ym].paypal = entry.total; }
    });
    sales.cash.forEach((entry) => {
        if(months.hasOwnProperty(entry.ym)) { months[entry.ym].cash = entry.total; }
    });

    return Object.keys(months).map(ym => months[ym]);
}