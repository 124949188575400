import {

    SEND_ORDER_REQUEST,
    SEND_ORDER_SUCCESS,
    SEND_ORDER_FAILURE,

    FIND_ORDER_REQUEST,
    FIND_ORDER_SUCCESS,
    FIND_ORDER_FAILURE,

    UPDATE_ORDER_REQUEST,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_FAILURE,

    FIND_ORDERS_REQUEST,
    FIND_ORDERS_SUCCESS,
    FIND_ORDERS_FAILURE,

    SEND_PAY_PAL_SUBMIT_ORDER_REQUEST,
    SEND_PAY_PAL_SUBMIT_ORDER_SUCCESS,
    SEND_PAY_PAL_SUBMIT_ORDER_FAILURE,

    FIND_SALES_DATA_REQUEST,
    FIND_SALES_DATA_SUCCESS,
    FIND_SALES_DATA_FAILURE,

    AUTHENTICATE_FAILURE,
    LOGOUT_USER,
    CLEAR_ORDER,

} from 'Redux/Action';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer, getSingleObject } from 'ClientService/ReducerUtil';
import { Reducer } from "redux";
import { OrderState, TOrderState } from "Redux/Store/Order/OrderState";

const initialState = OrderState.getInitialState();

export const orderReducer: Reducer<TOrderState> = (state = initialState, action) => {

    switch (action.type) {

    case CLEAR_ORDER :
    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
        return {
            ...initialState,
        };

    case SEND_PAY_PAL_SUBMIT_ORDER_REQUEST:
    case FIND_ORDER_REQUEST:
    case FIND_ORDERS_REQUEST:
    case FIND_SALES_DATA_REQUEST:
    case UPDATE_ORDER_REQUEST:
    case SEND_ORDER_REQUEST:
        return {
            ...state,
            lastInteractiveId: null,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case FIND_ORDERS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            order: {
                entries: action.payload.orders,
                maxCount: (action.meta) ? action.meta.response.count : state.order.maxCount,
            },
            updatedAt: Date.now(),
        };

    case FIND_SALES_DATA_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            sales: action.payload.salesData,
            updatedAt: Date.now(),
        };

    case UPDATE_ORDER_SUCCESS:
    case FIND_ORDER_SUCCESS:
    case SEND_ORDER_SUCCESS:
    case SEND_PAY_PAL_SUBMIT_ORDER_SUCCESS:
        const entry = getSingleObject(action.payload);

        return {
            ...state,
            lastInteractiveId: entry.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            order: {
                ...state.order,
                entries: addSingleObjectToReducer(state.order.entries, entry)
            },
        };

    case SEND_PAY_PAL_SUBMIT_ORDER_FAILURE:
    case FIND_ORDER_FAILURE:
    case FIND_ORDERS_FAILURE:
    case SEND_ORDER_FAILURE:
    case FIND_SALES_DATA_FAILURE:
    case UPDATE_ORDER_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



    default:
        return state;
    }
};
