import {IReduxStore} from "Redux/Store/IReduxStore";
import moment from "moment";

export interface IMonthShippingEntry {
    name: string,
    total: number,
    deliver: number,
    pickUp: number
}


export const getShippingSalesData = (state: IReduxStore): IMonthShippingEntry[] => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 1);

    const months: Record<string, IMonthShippingEntry> = {};
    const sales = state.order.sales;

    for(let i = 0; i <= 12; i++) {
        const ym = moment(date).format('YYYY-MM');
        months[ym] = { name: moment(date).format('YYYY-MM'), total: 0, deliver: 0, pickUp: 0 };
        date.setMonth(date.getMonth()+1);
    }


    sales.total.forEach((entry) => {
        if(months.hasOwnProperty(entry.ym)) { months[entry.ym].total = entry.count; }
    });
    sales.deliver.forEach((entry) => {
        if(months.hasOwnProperty(entry.ym)) { months[entry.ym].deliver = entry.count; }
    });
    sales.pickUp.forEach((entry) => {
        if(months.hasOwnProperty(entry.ym)) { months[entry.ym].pickUp = entry.count; }
    });

    return Object.keys(months).map(ym => months[ym]);
}