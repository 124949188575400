import { combineEpics }   from 'redux-observable';
import NotificationEpics  from "./Notification";
import GlobalEpics        from "./Global";
import UserEpics          from "./User";
import AllergenEpics      from "./Allergen";
import IngredientEpics    from "./Ingredient";
import MealCategoryEpics  from "./MealCategory";
import MealEpics          from "./Meal";
import ReductionEpics     from "./Reduction";
import OrderEpics         from "./Order";
import BasketEpics        from "./Basket";

export default combineEpics(
    NotificationEpics,
    GlobalEpics,
    UserEpics,
    AllergenEpics,
    IngredientEpics,
    MealCategoryEpics,
    MealEpics,
    ReductionEpics,
    OrderEpics,
    BasketEpics,
);
