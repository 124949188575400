import {
    CREATE_MEAL_CATEGORY_REQUEST,
    CREATE_MEAL_CATEGORY_SUCCESS,
    CREATE_MEAL_CATEGORY_FAILURE,

    DELETE_MEAL_CATEGORY_REQUEST,
    DELETE_MEAL_CATEGORY_SUCCESS,
    DELETE_MEAL_CATEGORY_FAILURE,

    UPDATE_MEAL_CATEGORY_REQUEST,
    UPDATE_MEAL_CATEGORY_SUCCESS,
    UPDATE_MEAL_CATEGORY_FAILURE,

    FIND_MEAL_CATEGORY_REQUEST,
    FIND_MEAL_CATEGORY_SUCCESS,
    FIND_MEAL_CATEGORY_FAILURE,

    FIND_MEAL_CATEGORIES_REQUEST,
    FIND_MEAL_CATEGORIES_SUCCESS,
    FIND_MEAL_CATEGORIES_FAILURE,

    FIND_ALL_MEAL_CATEGORIES_REQUEST,
    FIND_ALL_MEAL_CATEGORIES_SUCCESS,
    FIND_ALL_MEAL_CATEGORIES_FAILURE,

    SEARCH_MEAL_CATEGORIES_REQUEST,
    SEARCH_MEAL_CATEGORIES_SUCCESS,
    SEARCH_MEAL_CATEGORIES_FAILURE,

    LOAD_MENU_CARD_SUCCESS,

    LOGOUT_USER,
    AUTHENTICATE_FAILURE,

} from 'Redux/Action';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer, getSingleObject } from 'ClientService/ReducerUtil';
import { Reducer } from "redux";
import { MealCategoryState, TMealCategoryState } from "Redux/Store/MealCategory/MealCategoryState";

const initialState = MealCategoryState.getInitialState();

export const mealCategoryReducer: Reducer<TMealCategoryState> = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
        return {
            ...initialState,
        };

    case CREATE_MEAL_CATEGORY_REQUEST:
    case DELETE_MEAL_CATEGORY_REQUEST:
    case UPDATE_MEAL_CATEGORY_REQUEST:
    case FIND_MEAL_CATEGORY_REQUEST:
    case FIND_MEAL_CATEGORIES_REQUEST:
    case SEARCH_MEAL_CATEGORIES_REQUEST:
    case FIND_ALL_MEAL_CATEGORIES_REQUEST:
        return {
            ...state,
            lastInteractiveId: null,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case CREATE_MEAL_CATEGORY_SUCCESS:
    case UPDATE_MEAL_CATEGORY_SUCCESS:
    case FIND_MEAL_CATEGORY_SUCCESS:
        const entry = getSingleObject(action.payload);
        return {
            ...state,
            lastInteractiveId: entry.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            mealCategory: {
                ...state.mealCategory,
                entries: addSingleObjectToReducer(state.mealCategory.entries, entry)
            },
        };

    case FIND_MEAL_CATEGORIES_SUCCESS:
    case FIND_ALL_MEAL_CATEGORIES_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            mealCategory: {
                entries: action.payload.mealCategories,
                maxCount: (action.meta) ? action.meta.response.count : state.mealCategory.maxCount,
            },
            updatedAt: Date.now(),
        };

    case SEARCH_MEAL_CATEGORIES_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            searchEntries: {
                ...state.searchEntries,
                ...action.payload.mealCategories,
            },
            updatedAt: Date.now(),
        };

    case LOAD_MENU_CARD_SUCCESS:
        return {
            ...state,
            mealCategory: {
                entries: action.payload.mealCategories,
                maxCount: action.payload.mealCategories.length,
            },
            updatedAt: Date.now(),
        };


    case DELETE_MEAL_CATEGORY_SUCCESS: {
        const entries = { ...state.mealCategory.entries };
        delete entries[action.payload.id];
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            mealCategory: {
                ...state.mealCategory,
                entries: entries,
            },
        };
    }

    case CREATE_MEAL_CATEGORY_FAILURE:
    case DELETE_MEAL_CATEGORY_FAILURE:
    case UPDATE_MEAL_CATEGORY_FAILURE:
    case FIND_MEAL_CATEGORY_FAILURE:
    case FIND_MEAL_CATEGORIES_FAILURE:
    case SEARCH_MEAL_CATEGORIES_FAILURE:
    case FIND_ALL_MEAL_CATEGORIES_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



    default:
        return state;
    }
};
