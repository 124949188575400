import {
    UPDATE_SERVER_SETTINGS_REQUEST,
    updateServerSettingsSuccess,
    updateServerSettingsFailure,
    showSuccessNotification,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const updateServerSettingsRequestEpic: TEpic = (action$, store$, { SettingRepository }) => {
    return action$.pipe(
        ofType(UPDATE_SERVER_SETTINGS_REQUEST),
        mergeMap(action => {
            return from(SettingRepository.updateOptions(action.payload.updateData)).pipe(
                mergeMap(( { options } ) => {
                    return from([
                        updateServerSettingsSuccess({ options }),
                        showSuccessNotification('Die Speicherung der ServerSettings war erfolgreich.')
                    ]);
                }),
                catchError((error: Error) => of(updateServerSettingsFailure(error)))
            )
        })
    );
}
