import {IReduxStore} from "Redux/Store/IReduxStore";
import {getEasterHolidays, getServerOption, THoliday} from "Redux/Query";
import moment from "moment";

export const isHoliday = (state: IReduxStore): THoliday|false => {
    const holidays   = [
        ...(getServerOption(state, 'holidays', []) as THoliday[]),
        ...getEasterHolidays(),
    ];

    const now = moment().format('DDMM');
    for(let holiday of holidays) {
        if(!holiday.active || holiday.dayString !== now) { continue; }
        return holiday;
    }
    return false;

}