import { easter } from "date-easter";
import moment from "moment";
import { createUniqKey } from "ClientService";


export type THoliday = {
    key?: string,
    active: boolean,
    dayString: string|null,
    name: string|null,
    changeable?: boolean,
    new?: boolean,
}


export const getEasterHolidays = (year: number|null = null): THoliday[] => {
    if(year === null) {
        year = parseInt(moment(new Date()).format('YYYY'));
    }
    const easterObj = easter(year);
    let easterDate: Date = new Date();
    easterDate.setMonth(easterObj.month - 1);
    easterDate.setDate(easterObj.day);

    return [
        { key: createUniqKey(), changeable: false, active: true, dayString: moment(easterDate.setDate(easterObj.day -  2)).format('DDMM'), name: 'Karfreitag' },
        { key: createUniqKey(), changeable: false, active: true, dayString: moment(easterDate.setDate(easterObj.day     )).format('DDMM'), name: 'Ostersonntag' },
        { key: createUniqKey(), changeable: false, active: true, dayString: moment(easterDate.setDate(easterObj.day +  1)).format('DDMM'), name: 'Ostermontag' },
        { key: createUniqKey(), changeable: false, active: true, dayString: moment(easterDate.setDate(easterObj.day + 39)).format('DDMM'), name: 'Himmelfahrt' },
        { key: createUniqKey(), changeable: false, active: true, dayString: moment(easterDate.setDate(easterObj.day + 49)).format('DDMM'), name: 'Pfingstsonntag' },
        { key: createUniqKey(), changeable: false, active: true, dayString: moment(easterDate.setDate(easterObj.day + 50)).format('DDMM'), name: 'Pfingstmontag' },
    ];
}