import { combineEpics } from 'redux-observable';

import { createAllergenEpic }   from './CreateAllergen/CreateAllergenEpic';
import { deleteAllergenEpic }   from './DeleteAllergen/DeleteAllergenEpic';
import { findAllergeneEpic }   from './FindAllergene/FindAllergeneEpic';
import { findAllergenEpic }     from './FindAllergen/FindAllergenEpic';
import { searchAllergeneEpic } from './SearchAllergene/SearchAllergeneEpic';
import { updateAllergenEpic }   from './UpdateAllergen/UpdateAllergenEpic';


export default combineEpics(
    createAllergenEpic,
    deleteAllergenEpic,
    findAllergeneEpic,
    findAllergenEpic,
    searchAllergeneEpic,
    updateAllergenEpic,
);
