export const encodeUrl = (param: unknown) => {
    return encodeURIComponent(`${param}`.toLowerCase().trim()
        .replace(/\//g, '-')
        .replace(/ - /g, '-')
        .replace(/,/g, '')
        .replace(/ /g, '-')
        .replace(/ä/g, 'ae')
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .replace(/ß/g, 'ss')
    );
}