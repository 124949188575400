import { BaseStoreNamespace } from "Redux/Store/BaseStore"
import { Meal } from "Domain/Model/Meal/Meal";

interface IMealState {
    meal: {
        entries: Record<string, Meal>
        maxCount: number;
    },
    searchEntries: Record<string, Meal>,
    lastInteractiveId: null|string|number,
}

export type TMealState = IMealState & BaseStoreNamespace.TDefaultState;


export const MealState = {
    getInitialState: (): TMealState => ({
        openRequests:   [],
        failedRequests: [],
        updatedAt:      0,
        searchEntries:  {},
        meal: {
            entries: {},
            maxCount: 0,
        },
        lastInteractiveId: null,
    })
}