import { IReduxStore } from "Redux/Store/IReduxStore";
import {
    FIND_INGREDIENT_REQUEST,
    FIND_INGREDIENTS_REQUEST,
    SEARCH_INGREDIENTS_REQUEST,
    CREATE_INGREDIENT_REQUEST,
    UPDATE_INGREDIENT_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isIngredientLoading = (state: IReduxStore): boolean =>
    ArrayContains(state.ingredient.openRequests, [
        FIND_INGREDIENT_REQUEST,
        FIND_INGREDIENTS_REQUEST,
        SEARCH_INGREDIENTS_REQUEST,
        CREATE_INGREDIENT_REQUEST,
        UPDATE_INGREDIENT_REQUEST,
    ]);