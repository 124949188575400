import BaseRepository from 'Domain/Repository/BaseRepository/BaseRepository';
import { IOrderData, Order } from "Domain/Model/Order/Order";
import {ISalesDataResponse} from "../../../Redux/Action";

const config = {
    model: Order,
    baseRoute: '/order',
    singular: 'order',
    plural: 'orders',
}

class OrderRepository extends BaseRepository<Order, typeof config.singular, typeof config.plural> {

    send( orderData: IOrderData ) {
        return this.makeRequest({
            path: `/order/send`,
            type: 'POST',
            data: orderData,
            callback: this.initializeSingleModel,
        });
    }

    sendPayPalSubmit({ orderId, payPalTransaction }: { orderId: string, payPalTransaction: any }) {
        return this.makeRequest({
            path: `/order/submit/pay-pal/${orderId}`,
            type: 'POST',
            data: payPalTransaction,
            callback: this.initializeSingleModel,
        });
    }

    findSalesData() {
        return this.makeRequest({
            path: `/admin/order/sales-data`,
            type: 'GET',
            callback: this.initializeSalesData,
        });
    }

    initializeSalesData(response: { salesData: ISalesDataResponse })  {
        return response.salesData;
    }

}

export default new OrderRepository(config);
