import { IReduxStore } from "Redux/Store/IReduxStore";
import { filterObjectStore, queryFilter, IFilterProps } from 'Redux/Query';
import { Meal } from "Domain/Model/Meal/Meal";

export const getMealsBySearch = (state: IReduxStore, filter: null|IFilterProps<Meal> = null) => {
    if(filter === null) {
        filter = queryFilter({sorting: { by: 'name', order: 'ASC' }}) as IFilterProps<Meal>;
    }

    return filterObjectStore(state.meal.searchEntries, filter);
};
