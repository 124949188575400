import {
    UPDATE_MEAL_REQUEST,
    updateMealSuccess,
    updateMealFailure,
    showSuccessNotification
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const updateMealEpic: TEpic = (action$, store$, { MealRepository }) => {
    return action$.pipe(
        ofType(UPDATE_MEAL_REQUEST),
        mergeMap(action => from(MealRepository.update( action.payload.id, action.payload.updateData ))
            .pipe(
                mergeMap((ingredient) => {
                    return from([
                        showSuccessNotification(`Die Speicherung des Gerichtes war erfolgreich.`),
                        updateMealSuccess(ingredient),
                    ]);
                }),
                catchError( error => {
                    return from([
                        updateMealFailure(error)
                    ])
                })
            )
        ),
    );
}
