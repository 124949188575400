import {

    GET_SETTINGS_REQUEST,
    GET_SETTINGS_FAILURE,
    GET_SETTINGS_SUCCESS,

    RELOAD_SERVER_SETTINGS_REQUEST,
    RELOAD_SERVER_SETTINGS_SUCCESS,
    RELOAD_SERVER_SETTINGS_FAILURE,

    UPDATE_SERVER_SETTINGS_REQUEST,
    UPDATE_SERVER_SETTINGS_SUCCESS,
    UPDATE_SERVER_SETTINGS_FAILURE,

    SEND_ORDER_REQUEST,
    CLEAR_ORDER,

    SET_USER_DATA,

    SEND_ORDER_SUCCESS,
    SEND_ORDER_FAILURE,
    FIND_ORDER_SUCCESS,

    SEND_PAY_PAL_SUBMIT_ORDER_SUCCESS,

    APPLICATION_BOOTSTRAPPED,

} from "Redux/Action";
import { removeOpenRequest, removeFailedRequest, getSingleObject } from 'ClientService/ReducerUtil';
import { Reducer } from "redux";
import { PersistState, TPersistState } from "Redux/Store/Persist/PersistState";

const initialState = PersistState.getInitialState();

export const persistReducer: Reducer<TPersistState> = (state = initialState, action ) => {
    switch( action.type ) {

        case APPLICATION_BOOTSTRAPPED:
            const updatedAt = Date.now();
            if(updatedAt - state.updatedAt > 3600 * 1000) {
                return {
                    ...state,
                    updatedAt,
                    lastOrder: null,
                }
            }

            return {
                ...state,
                updatedAt: Date.now(),
            }

        case RELOAD_SERVER_SETTINGS_REQUEST:
        case UPDATE_SERVER_SETTINGS_REQUEST:
        case GET_SETTINGS_REQUEST:
            return {
                ...state,
                openRequests: [...new Set([...state.openRequests, action.type])],
                failedRequests: removeFailedRequest(action, state),
                updatedAt: Date.now(),
            };

        case GET_SETTINGS_SUCCESS:
        case UPDATE_SERVER_SETTINGS_SUCCESS:
        case RELOAD_SERVER_SETTINGS_SUCCESS:
            return {
                ...state,
                openRequests: removeOpenRequest(action, state),
                updatedAt: Date.now(),
                settings: action.payload.options,
            };

        case SET_USER_DATA:
            return {
                ...state,
                updatedAt: Date.now(),
                userData: action.payload,
            };

        case CLEAR_ORDER:
        case SEND_ORDER_REQUEST:
        case SEND_ORDER_FAILURE:
            return {
                ...state,
                updatedAt: Date.now(),
                lastOrder: null,
            };

        case FIND_ORDER_SUCCESS: {
            const lastOrder = getSingleObject(action.payload);

            return {
                ...state,
                updatedAt: Date.now(),
                lastOrder: lastOrder[Object.keys(lastOrder)[0]],
            };
        }

        case SEND_PAY_PAL_SUBMIT_ORDER_SUCCESS:
        case SEND_ORDER_SUCCESS: {
            const lastOrder = getSingleObject(action.payload);

            return {
                ...state,
                updatedAt: Date.now(),
                lastOrder,
            };
        }

        case GET_SETTINGS_FAILURE:
        case UPDATE_SERVER_SETTINGS_FAILURE:
        case RELOAD_SERVER_SETTINGS_FAILURE:
            return {
                ...state,
                openRequests: removeOpenRequest(action, state),
                failedRequests: [...new Set([...state.failedRequests, action.type])],
                updatedAt: Date.now(),
            };


        default:
            return state;
    }
};