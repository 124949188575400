import { BaseStoreNamespace }  from 'Redux/Store/BaseStore';
import { Order } from "Domain/Model/Order/Order";
import { ISalesDataResponse } from "Redux/Action";

interface IOrderState {
    order: {
        entries: Record<string, Order>
        maxCount: number;
    },
    sales: ISalesDataResponse,
    searchEntries: Record<string, Order>,
    lastInteractiveId: null|string|number,
}

export type TOrderState = IOrderState & BaseStoreNamespace.TDefaultState;


export const OrderState = {

    getInitialState: (): TOrderState => ({
        updatedAt      : 0,
        openRequests   : [],
        failedRequests : [],
        searchEntries:  {},
        order: {
            entries: {},
            maxCount: 0,
        },
        lastInteractiveId: null,
        sales: {
            total: [],
            paypal: [],
            cash: [],
            deliver: [],
            pickUp: [],
            stats: {
                total: [],
                year: [],
                month: [],
                day: [],
            },
            month: [],
            lastMonth: [],
        }
    })

}