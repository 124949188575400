import { Reduction } from "Domain/Model/Reduction/Reduction";

export const FIND_REDUCTIONS_SUCCESS = 'FIND_REDUCTIONS_SUCCESS';

interface IFindReductionsSuccess {
    reductions: Record<string, Reduction>,
    request: {},
    response: {},
}

export const findReductionsSuccess = ({ reductions, request, response }: IFindReductionsSuccess) => ({
    type: FIND_REDUCTIONS_SUCCESS,
    payload: { reductions },
    meta: {
        request,
        response,
    }
});
