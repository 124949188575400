import BaseRepository   from 'Domain/Repository/BaseRepository/BaseRepository';
import { Option }       from 'Domain/Model/Setting/Option';
import { MealCategory, IMealCategory } from "Domain/Model/MealCategory/MealCategory";
import { Ingredient, IIngredient }     from "Domain/Model/Ingredient/Ingredient";
import { Allergen, IAllergen }         from "Domain/Model/Allergen/Allergen";
import { Meal, IMeal }                 from "Domain/Model/Meal/Meal";
import { BaseModel }                   from "Domain/Model/BaseModel/BaseModel";
import { Reduction, IReduction }       from "Domain/Model/Reduction/Reduction";

const config = {
    model: Option,
    baseRoute: '/settings',
    singular: 'option',
    plural: 'options',
}

interface ICardPayload {
    mealCategories: IMealCategory[],
    ingredients:    IIngredient[],
    allergens:      IAllergen[],
    meals:          IMeal[],
    reductions:     IReduction[],
}

interface ICardResponse {
    mealCategories: Record<string, MealCategory>,
    ingredients:    Record<string, Ingredient>,
    allergens:      Record<string, Allergen>,
    meals:          Record<string, Meal>,
    reductions:     Record<string, Reduction>,
}

class SettingRepository extends BaseRepository<Option, typeof config.singular, typeof config.plural> {

    findAllOptions( filterSettings = { filter: [] }) {
        return this.makeRequest({
            path: `/public/client/settings`,
            type: 'GET',
            callback: this.initializeSettings,
        });
    }

    updateOptions(updateData = {}) {
        return this.makeRequest({
            path: `/admin/settings`,
            type: 'PATCH',
            data: updateData,
            callback: this.initializeSettings,
        });
    }

    reloadSettings() {
        return this.makeRequest({
            path: `/admin/settings/reload`,
            type: 'GET',
            callback: this.initializeSettings,
        });
    }


    loadCard() {
        return this.makeRequest({
            path: `/public/menu-card`,
            type: 'GET',
            callback: this.initializeMenuCard,
        });
    }

    reloadCard() {
        return this.makeRequest({
            path: `/admin/menu-card/refresh`,
            type: 'GET',
            callback: () => {},
        });
    }


    initializeSettings = (responseObject: { options: Record<string, unknown>}): { options: Record<string, unknown>} => {
        return { options: responseObject.options };
    };

    initializeMenuCard = (responseObject: ICardPayload): ICardResponse => {

        const parseEntries = <IModel extends BaseModel, TModel extends BaseModel>({ entries, Model}: { entries: IModel[], Model: { new(data: IModel): TModel } }) => {
            const returnEntries: Record<string, TModel> = {};
            entries.forEach((entry: IModel) => {
                returnEntries[entry.id] = new Model(entry) as TModel
            });
            return returnEntries;
        }

        return {
            mealCategories: parseEntries<IMealCategory, MealCategory>({ entries: responseObject.mealCategories, Model: MealCategory}),
            ingredients:    parseEntries<IIngredient, Ingredient>({ entries: responseObject.ingredients, Model: Ingredient}),
            allergens:      parseEntries<IAllergen, Allergen>({ entries: responseObject.allergens, Model: Allergen}),
            meals:          parseEntries<IMeal, Meal>({ entries: responseObject.meals, Model: Meal}),
            reductions:     parseEntries<IReduction, Reduction>({ entries: responseObject.reductions, Model: Reduction}),
        }
    };

}

export default new SettingRepository(config);
