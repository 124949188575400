import { IReduxStore } from "Redux/Store/IReduxStore";
import {
    SEND_ORDER_FAILURE,
    FIND_ORDER_FAILURE,
    UPDATE_ORDER_FAILURE,
    SEND_PAY_PAL_SUBMIT_ORDER_FAILURE
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isOrderFailed = (state: IReduxStore): boolean => ArrayContains(state.order.failedRequests, [
    SEND_ORDER_FAILURE,
    FIND_ORDER_FAILURE,
    UPDATE_ORDER_FAILURE,
    SEND_PAY_PAL_SUBMIT_ORDER_FAILURE,
]);