import {
    CREATE_ALLERGEN_REQUEST,
    createAllergenSuccess,
    createAllergenFailure,
    showSuccessNotification
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const createAllergenEpic: TEpic = (action$, store$, { AllergenRepository }) => {
    return action$.pipe(
        ofType(CREATE_ALLERGEN_REQUEST),
        mergeMap(action => from(AllergenRepository.create( action.payload ))
            .pipe(
                mergeMap((allergen) => {
                    return from([
                        showSuccessNotification(`Allergen wurde erfolgreich erstellt`),
                        createAllergenSuccess( allergen ),
                    ]);
                }),
                catchError( error => {
                    return from([
                        createAllergenFailure(error),
                    ])
                })
            )
        ),
    );
}
