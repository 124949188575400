interface IPoint {
    lat: number,
    lng: number,
}

export const getDistanceBetweenPoints = ({ lat: lat1, lng: lon1 }: IPoint, { lat: lat2, lng: lon2 }: IPoint) => {
    const R = 6378.137;
    const dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
    const dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
    const s    = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(s), Math.sqrt(1-s));
    const d = R * c;

    return Math.floor(d * 1000); // meters
}
