
export enum PageEvents {
    // public
    HOME_PAGE          = 'HOME_PAGE',
    LOGIN_PAGE         = 'LOGIN_PAGE',
    TERMS_OF_AGREEMENT = 'TERMS_OF_AGREEMENT',
    IMPRINT            = 'IMPRINT',
    TERMS_OF_USE       = 'TERMS_OF_USE',

    // private
    DASHBOARD_PAGE     = 'DASHBOARD_PAGE',
    LOGOUT_PAGE        = 'LOGOUT_PAGE',
    ALLERGENE_PAGE     = 'ALLERGENE_PAGE',
    INGREDIENTS_PAGE   = 'INGREDIENTS_PAGE',
    MEAL_CATEGORY_PAGE = 'MEAL_CATEGORY_PAGE',
    MEAL_PAGE          = 'MEAL_PAGE',
    REDUCTION_PAGE     = 'REDUCTION_PAGE',
    SETTINGS_PAGE      = 'SETTINGS_PAGE',
    ORDERS_PAGE        = 'ORDERS_PAGE',
    ORDER_DETAIL_PAGE  = 'ORDER_DETAIL_PAGE',
    SALES_PAGE         = 'SALES_PAGE',

};