import { BaseStoreNamespace } from "Redux/Store/BaseStore"
import { Allergen } from "Domain/Model/Allergen/Allergen";

interface IAllergenState {
    allergen: {
        entries: Record<string, Allergen>
        maxCount: number;
    },
    searchEntries: Record<string, Record<string, Allergen>>,
    lastInteractiveId: null|string|number,
}

export type TAllergenState = IAllergenState & BaseStoreNamespace.TDefaultState;


export const AllergenState = {
    getInitialState: (): TAllergenState => ({
        openRequests:   [],
        failedRequests: [],
        updatedAt:      0,
        searchEntries:  {},
        allergen: {
            entries: {},
            maxCount: 0,
        },
        lastInteractiveId: null,
    })
}