interface IPayload {
    id: string,
    amount: number,
}

export const CHANGE_AMOUNT_FROM_BASKET_ENTRY = 'CHANGE_AMOUNT_FROM_BASKET_ENTRY';

export const changeAmountFromBasketEntry = (payload: IPayload) => ({
    type: CHANGE_AMOUNT_FROM_BASKET_ENTRY,
    payload,
})