import { BaseStoreNamespace } from "Redux/Store/BaseStore"
import { Reduction } from "Domain/Model/Reduction/Reduction";

interface IReductionState {
    reduction: {
        entries: Record<string, Reduction>
        maxCount: number;
    },
    searchEntries: Record<string, Record<string, Reduction>>,
    lastInteractiveId: null|string|number,
}

export type TReductionState = IReductionState & BaseStoreNamespace.TDefaultState;


export const ReductionState = {
    getInitialState: (): TReductionState => ({
        openRequests:   [],
        failedRequests: [],
        updatedAt:      0,
        searchEntries:  {},
        reduction: {
            entries: {},
            maxCount: 0,
        },
        lastInteractiveId: null,
    })
}