export interface ISalesData {
    ym: string,
    count: number,
    total: number,
    average: number,
}

export interface ISalesDataResponse {
    total: ISalesData[],
    paypal: ISalesData[],
    cash: ISalesData[],
    deliver: ISalesData[],
    pickUp: ISalesData[],
    lastMonth: any,
    month: any,
    stats: any,
}

export const FIND_SALES_DATA_SUCCESS = 'FIND_SALES_DATA_SUCCESS';

export const findSalesDataSuccess = (salesData: ISalesDataResponse) => ({
    type: FIND_SALES_DATA_SUCCESS,
    payload: { salesData },
});
