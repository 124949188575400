import { combineEpics } from 'redux-observable';

import { createReductionEpic }  from './CreateReduction/CreateReductionEpic';
import { deleteReductionEpic }  from './DeleteReduction/DeleteReductionEpic';
import { findReductionsEpic }   from './FindReductions/FindReductionsEpic';
import { findReductionEpic }    from './FindReduction/FindReductionEpic';
import { searchReductionsEpic } from './SearchReductions/SearchReductionsEpic';
import { updateReductionEpic }  from './UpdateReduction/UpdateReductionEpic';


export default combineEpics(
    createReductionEpic,
    deleteReductionEpic,
    findReductionsEpic,
    findReductionEpic,
    searchReductionsEpic,
    updateReductionEpic,
);
