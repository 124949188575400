/**
 * @param array
 * @param value
 * @param key = 'id'
 * @returns {{entry: null, index: number}|{entry, index: number}}
 */
export const getObjectByIdFromArray = (array, value, key = 'id') => {
    for(let i = 0, l = array.length; i < l; i++) {
        const entry = array[i];
        if(entry[key] !== value) {
            continue;
        }
        return { entry: { ...entry }, index: i };
    }
    return { entry: null, index: -1 };
}