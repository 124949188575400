import { combineEpics } from 'redux-observable';

import { sendOrderEpic } from './SendOrder/SendOrderEpic';
import { findOrderEpic } from './FindOrder/FindOrderEpic';
import { findOrdersEpic } from './FindOrders/FindOrdersEpic';
import { updateOrderEpic } from './UpdateOrder/UpdateOrderEpic';
import { sendPayPalSubmitOrderEpic } from './SendPayPalSubmitOrder/SendPayPalSubmitOrderEpic';
import { findSalesDataEpic } from './FindSalesData/FindSalesDataEpic';


export default combineEpics(
    sendOrderEpic,
    findOrderEpic,
    findOrdersEpic,
    updateOrderEpic,
    sendPayPalSubmitOrderEpic,
    findSalesDataEpic,
);
