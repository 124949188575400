import BaseRepository from 'Domain/Repository/BaseRepository/BaseRepository';
import { Reduction } from 'Domain/Model/Reduction/Reduction';

const config = {
    model: Reduction,
    baseRoute: '/reduction',
    singular: 'reduction',
    plural: 'reductions',
}

class ReductionRepository extends BaseRepository<Reduction, typeof config.singular, typeof config.plural> {

}

export default new ReductionRepository(config);
