import { Ingredient } from "Domain/Model/Ingredient/Ingredient";

export const FIND_INGREDIENTS_SUCCESS = 'FIND_INGREDIENTS_SUCCESS';

interface IFindIngredientsSuccess {
    ingredients: Record<string, Ingredient>,
    request: {},
    response: {},
}

export const findIngredientsSuccess = ({ ingredients, request, response }: IFindIngredientsSuccess) => ({
    type: FIND_INGREDIENTS_SUCCESS,
    payload: { ingredients },
    meta: {
        request,
        response,
    }
});
