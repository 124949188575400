import { lazy } from "react";
import { IRoute } from "./IRoute";
import { PageEvents } from "Component/Page/PageEvent";

export const PrivateRoutes: {[key:string]: IRoute} = {

    dashboard: {
        path: '/admin/umsaetze',
        component: lazy(() => (import('Component/Page/Private/Sales/SalesOverview'))),
        sideboard: {
            sorting: 20,
            icon: 'appstore',
            label: 'Dashboard',
        },
        pageKey: PageEvents.SALES_PAGE,
    },
    ordersOverview: {
        path: '/admin/bestellungen',
        component: lazy(() => (import('Component/Page/Private/Order/OrderOverview'))),
        sideboard: {
            sorting: 100,
            icon: 'order',
            label: 'Bestellungen',
        },
        pageKey: PageEvents.ORDERS_PAGE,
    },
    orderDetail: {
        path: '/admin/bestellungen/:id',
        component: lazy(() => (import('Component/Page/Private/Order/OrderDetail'))),
        pageKey: PageEvents.ORDER_DETAIL_PAGE,
        parent: 'ordersOverview',
    },

    mealsOverview: {
        path: '/admin/gerichte',
        component: lazy(() => (import('Component/Page/Private/Meal/MealOverview'))),
        sideboard: {
            sorting: 100,
            icon: 'coffee',
            label: 'Gerichte',
        },
        pageKey: PageEvents.MEAL_PAGE,
    },
    mealCategory: {
        path: '/admin/gruppen',
        component: lazy(() => (import('Component/Page/Private/MealCategory/MealCategory'))),
        sideboard: {
            sorting: 200,
            icon: 'folder',
            label: 'Gruppen',
        },
        pageKey: PageEvents.MEAL_CATEGORY_PAGE,
    },
    ingredients: {
        path: '/admin/zutaten',
        component: lazy(() => (import('Component/Page/Private/Ingredient/Ingredient'))),
        sideboard: {
            sorting: 300,
            icon: 'ingredient',
            label: 'Zutaten',
        },
        pageKey: PageEvents.INGREDIENTS_PAGE,
    },
    allergene: {
        path: '/admin/allergene',
        component: lazy(() => (import('Component/Page/Private/Allergene/Allergene'))),
        sideboard: {
            sorting: 400,
            icon: 'allergene',
            label: 'Allergene',
        },
        pageKey: PageEvents.ALLERGENE_PAGE,
    },
    reductions: {
        path: '/admin/rabatte',
        component: lazy(() => (import('Component/Page/Private/Reduction/ReductionOverview'))),
        sideboard: {
            sorting: 500,
            icon: 'reduction',
            label: 'Rabatte',
        },
        pageKey: PageEvents.REDUCTION_PAGE,
    },
    settings: {
        path: '/admin/einstellungen',
        component: lazy(() => (import('Component/Page/Private/Setting/SettingsOverview'))),
        sideboard: {
            sorting: 900,
            icon: 'setting',
            label: 'System',
        },
        pageKey: PageEvents.SETTINGS_PAGE,
    },

    logout: {
        path: '/logout',
        sideboard: {
            sorting: 1000,
            icon: 'logout',
            label: 'Abmelden',
        },
        pageKey: PageEvents.LOGOUT_PAGE
    },
}