import {
    CHECK_BASKET_VALUES,
    showInfoNotification,
    changeBasketEntryTotalPrice,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { EMPTY, from } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { TEpic } from "Redux/Config";
import { getActiveReduction, getBasketEntries, getMeal, getMealCategory } from "Redux/Query";


export const checkBasketValuesEpic: TEpic = (action$, store$, { AllergenRepository }) => {
    return action$.pipe(
        ofType(CHECK_BASKET_VALUES),
        withLatestFrom( store$ ),
        mergeMap(([action, store]) => {
            const actions = [];
            const basketEntries = getBasketEntries(store);

            if(!basketEntries.length) {
                return EMPTY;
            }
            basketEntries.forEach(basketEntry => {
                const meal     = getMeal(store, basketEntry.mealId);
                const category = getMealCategory(store, basketEntry.categoryId);
                let mealPrice  = getActiveReduction(store)(meal.price, meal, category);

                if(basketEntry.selections.length) {
                    for(const selection of basketEntry.selections) {
                        let additionalPrice = getActiveReduction(store)(parseFloat(`${meal.price}`) + parseFloat(`${meal.selectionOptions[selection.optionId]?.price || 0}`), meal, category, selection.optionId);
                        additionalPrice = parseFloat((additionalPrice - mealPrice).toFixed(2));
                        mealPrice += additionalPrice;
                    }
                }

                if(basketEntry.toppings.length) {
                    for(const topping of basketEntry.toppings) {
                        mealPrice += parseFloat(topping.price.toFixed(2));
                    }
                }

                if(parseFloat(mealPrice.toFixed(2)) !== parseFloat(basketEntry.totalPrice.toFixed(2))) {
                    actions.push(changeBasketEntryTotalPrice({ basketEntryId: basketEntry.id, totalPrice: parseFloat(mealPrice.toFixed(2)) }));
                }
            });

            if(actions.length) {
                actions.push(showInfoNotification("Die Preise in deinen Warenkorb wurden aktuallisiert"));
                return from(actions);
            }

            return EMPTY;
        })
    );
}
