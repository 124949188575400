import { FIND_INGREDIENTS_REQUEST, findIngredientsSuccess, findIngredientsFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const findIngredientsEpic: TEpic = (action$, store$, { IngredientRepository }) => {
    return action$.pipe(
        ofType( FIND_INGREDIENTS_REQUEST ),
        mergeMap(action => from(
            IngredientRepository.findAll(action.payload)
                .then(({ ingredients, meta }) => findIngredientsSuccess({ ingredients, response: meta, request: action}))
                .catch((error: Error) => findIngredientsFailure(error)),
        )),
    );
}
