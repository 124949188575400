import { FIND_ALL_MEAL_CATEGORIES_REQUEST, findAllMealCategoriesSuccess, findAllMealCategoriesFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const findAllMealCategoriesEpic: TEpic = (action$, store$, { MealCategoryRepository }) => {
    return action$.pipe(
        ofType( FIND_ALL_MEAL_CATEGORIES_REQUEST ),
        mergeMap(action => from(
            MealCategoryRepository.findAllCategories()
                .then(({ mealCategories, meta }) => findAllMealCategoriesSuccess({ mealCategories, response: meta, request: action}))
                .catch((error: Error) => findAllMealCategoriesFailure(error)),
        )),
    );
}
