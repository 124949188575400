import { MealCategory, IMealCategory } from "Domain/Model/MealCategory/MealCategory";

export interface IMealSelectionOption {
    id:     string,
    name?:  string,
    price?: string|number,
    selectionId: string,
}

export interface IMealSelection {
    id:        string,
    name?:     string,
    price?:    number,
    options?:  IMealSelectionOption[],
    category?: MealCategory|{},
}

export class MealSelection {

    public id:        string;
    public name?:     string;
    public price?:    number;
    public options:   IMealSelectionOption[];
    public category?: MealCategory|{};

    constructor(data: IMealSelection) {

        this.id       = data.id;
        this.name     = data.name;
        this.price    = data.price;
        this.options  = (data.options) ? data.options.map(option => ({ ...option, selectionId: this.id, price: parseFloat(`${option.price || 0}`.toString()) })) : [];
        this.category = data.category ? new MealCategory(data.category as IMealCategory) : {};


    }
}