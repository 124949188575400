import BaseRepository from 'Domain/Repository/BaseRepository/BaseRepository';
import { Ingredient } from 'Domain/Model/Ingredient/Ingredient';

const config = {
    model: Ingredient,
    baseRoute: '/ingredient',
    singular: 'ingredient',
    plural: 'ingredients',
}

class IngredientRepository extends BaseRepository<Ingredient, typeof config.singular, typeof config.plural> {

}

export default new IngredientRepository(config);
