import { AUTHENTICATE_SUCCESS, showInfoNotification } from 'Redux/Action';
import { ofType } from 'redux-observable';
import {mergeMap, withLatestFrom} from 'rxjs/operators';
import { of } from 'rxjs';
import { getSiteName } from "Redux/Query";
import { TEpic } from "Redux/Config";

export const authenticateSuccessEpic: TEpic = (action$, store$ ) => {
    return action$.pipe(
        ofType(AUTHENTICATE_SUCCESS),
        withLatestFrom( store$ ),
        mergeMap(([action, store]) => {
            const siteName = getSiteName(store);
            return of( showInfoNotification(`Willkommen im Backend von ${ siteName }!` ));
        })
    );
}
