import moment from "moment";

export interface IMonthSalesEntry {
    name: string,
    total: number,
    cash: number,
    paypal: number
}



export const getOrderSalesStructure = (): Record<string, IMonthSalesEntry> => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 1);

    const months: Record<string, IMonthSalesEntry> = {};

    for(let i = 0; i <= 12; i++) {
        const ym = moment(date).format('YYYY-MM');
        months[ym] = { name: moment(date).format('YYYY-MM'), total: 0, paypal: 0, cash: 0 };
        date.setMonth(date.getMonth()+1);
    }

    return months;
}