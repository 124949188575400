import {
    GET_SETTINGS_REQUEST,
    getSettingsSuccess,
    getSettingsFailure,
} from "Redux/Action";
import { TEpic } from "Redux/Config";
import { ofType } from "redux-observable";
import { mergeMap } from "rxjs/operators";
import { from } from "rxjs";


export const getSettingsEpic: TEpic = (action$, store$, { SettingRepository}) => (
    action$.pipe(
        ofType(GET_SETTINGS_REQUEST),
        mergeMap(action => from(
            SettingRepository.findAllOptions(action.payload)
                .then(({ options}) => getSettingsSuccess({ options }))
                .catch((error: Error) => getSettingsFailure(error)),
        )),

    )

);