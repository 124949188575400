import UserRepository         from './UserRepository/UserRepository';
import AllergenRepository     from './AllergenRepository/AllergenRepository';
import IngredientRepository   from './IngredientRepository/IngredientRepository';
import MealCategoryRepository from './MealCategoryRepository/MealCategoryRepository';
import MealRepository         from './MealRepository/MealRepository';
import ReductionRepository    from './ReductionRepository/ReductionRepository';
import SettingRepository      from './SettingRepository/SettingRepository';
import OrderRepository        from './OrderRepository/OrderRepository';

const repositories = {
    UserRepository,
    AllergenRepository,
    IngredientRepository,
    MealCategoryRepository,
    MealRepository,
    ReductionRepository,
    SettingRepository,
    OrderRepository,
};

export default repositories;