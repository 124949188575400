import { lazy } from "react";
import {IRoute} from "./IRoute";
import {PageEvents} from "Component/Page/PageEvent";

export const PublicRoutes: {[key:string]: IRoute} = {
    login: {
        path: '/admin',
        component: lazy(() => (import('Component/Page/Public/SignIn/SignIn'))),
        pageKey: PageEvents.LOGIN_PAGE,
    },
    termsOfAgreements: {
        path: '/datenschutz',
        component: lazy(() => (import('Component/Page/Public/TermsOfAgreement/TermsOfAgreement'))),
        pageKey: PageEvents.TERMS_OF_AGREEMENT,
    },
    imprint: {
        path: '/impressum',
        component: lazy(() => (import('Component/Page/Public/Imprint/Imprint'))),
        pageKey: PageEvents.IMPRINT,
    },
    termsOfUse: {
        path: '/allgemeine-geschaeftsbedingung',
        component: lazy(() => (import('Component/Page/Public/TermsOfUse/TermsOfUse'))),
        pageKey: PageEvents.TERMS_OF_USE,
    },

    home: {
        path: '/:category?',
        component: lazy(() => (import('Component/Page/Public/Home/Home'))),
        pageKey: PageEvents.HOME_PAGE,
    },
}
