import BaseRepository from 'Domain/Repository/BaseRepository/BaseRepository';
import { MealCategory } from 'Domain/Model/MealCategory/MealCategory';

const config = {
    model: MealCategory,
    baseRoute: '/meal-category',
    singular: 'mealCategory',
    plural: 'mealCategories',
}

class MealCategoryRepository extends BaseRepository<MealCategory, typeof config.singular, typeof config.plural> {

    async findAllCategories() {
        return this.makeRequest({
            path: '/meal-category/find/all',
            type: 'GET',
            callback: this.initializeModels
        });
    }

}

export default new MealCategoryRepository(config);
