import {
    CREATE_REDUCTION_REQUEST,
    CREATE_REDUCTION_SUCCESS,
    CREATE_REDUCTION_FAILURE,

    DELETE_REDUCTION_REQUEST,
    DELETE_REDUCTION_SUCCESS,
    DELETE_REDUCTION_FAILURE,

    UPDATE_REDUCTION_REQUEST,
    UPDATE_REDUCTION_SUCCESS,
    UPDATE_REDUCTION_FAILURE,

    FIND_REDUCTION_REQUEST,
    FIND_REDUCTION_SUCCESS,
    FIND_REDUCTION_FAILURE,

    FIND_REDUCTIONS_REQUEST,
    FIND_REDUCTIONS_SUCCESS,
    FIND_REDUCTIONS_FAILURE,

    SEARCH_REDUCTIONS_REQUEST,
    SEARCH_REDUCTIONS_SUCCESS,
    SEARCH_REDUCTIONS_FAILURE,

    LOAD_MENU_CARD_REQUEST,
    LOAD_MENU_CARD_SUCCESS,
    LOAD_MENU_CARD_FAILURE,

    LOGOUT_USER,
    AUTHENTICATE_FAILURE,

} from 'Redux/Action';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer, getSingleObject } from 'ClientService/ReducerUtil';
import { Reducer } from "redux";
import { ReductionState, TReductionState } from "Redux/Store/Reduction/ReductionState";

const initialState = ReductionState.getInitialState();

export const reductionReducer: Reducer<TReductionState> = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
        return {
            ...initialState,
        };

    case CREATE_REDUCTION_REQUEST:
    case DELETE_REDUCTION_REQUEST:
    case UPDATE_REDUCTION_REQUEST:
    case FIND_REDUCTION_REQUEST:
    case FIND_REDUCTIONS_REQUEST:
    case SEARCH_REDUCTIONS_REQUEST:
        return {
            ...state,
            lastInteractiveId: null,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case CREATE_REDUCTION_SUCCESS:
    case UPDATE_REDUCTION_SUCCESS:
    case FIND_REDUCTION_SUCCESS:
        const entry = getSingleObject(action.payload);
        return {
            ...state,
            lastInteractiveId: entry.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            reduction: {
                ...state.reduction,
                entries: addSingleObjectToReducer(state.reduction.entries, entry)
            },
        };

    case FIND_REDUCTIONS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            reduction: {
                entries: action.payload.reductions,
                maxCount: (action.meta) ? action.meta.response.count : state.reduction.maxCount,
            },
            updatedAt: Date.now(),
        };

    case SEARCH_REDUCTIONS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            searchEntries: {
                ...state.searchEntries,
                ...action.payload.reductionies,
            },
            updatedAt: Date.now(),
        };

    case LOAD_MENU_CARD_REQUEST:
    case LOAD_MENU_CARD_FAILURE:
        return {
            ...state,
            reduction: {
                entries: {},
                maxCount: 0,
            },
            updatedAt: Date.now(),
        };

    case LOAD_MENU_CARD_SUCCESS:
        return {
            ...state,
            reduction: {
                entries: action.payload.reductions,
                maxCount: action.payload.reductions.length,
            },
            updatedAt: Date.now(),
        };

    case DELETE_REDUCTION_SUCCESS: {
        const entries = { ...state.reduction.entries };
        delete entries[action.payload.id];
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            reduction: {
                ...state.reduction,
                entries: entries,
            },
        };
    }

    case CREATE_REDUCTION_FAILURE:
    case DELETE_REDUCTION_FAILURE:
    case UPDATE_REDUCTION_FAILURE:
    case FIND_REDUCTION_FAILURE:
    case FIND_REDUCTIONS_FAILURE:
    case SEARCH_REDUCTIONS_FAILURE:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



    default:
        return state;
    }
};
