import { IReduxStore } from "Redux/Store/IReduxStore";
import { filterObjectStore, queryFilter, IFilterProps } from 'Redux/Query';
import { Order } from "Domain/Model/Order/Order";

export const getOrders = (state: IReduxStore, filter: null|IFilterProps<Order> = null) => {
    if(filter === null) {
        filter = queryFilter({sorting: { by: 'createdAt', order: 'DESC' }}) as IFilterProps<Order>;
    }

    return filterObjectStore(state.order.order.entries, filter);
};
