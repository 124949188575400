export const SEND_PAY_PAL_SUBMIT_ORDER_REQUEST = 'SEND_PAY_PAL_SUBMIT_ORDER_REQUEST';

interface IPayPalTransaction {
    billingToken: string|null
    facilitatorAccessToken: string
    orderID: string
    payerID: string
    paymentID: string|null
}

export const sendPayPalSubmitOrderRequest = ( orderId: string, payPalTransaction: IPayPalTransaction ) => ({
    type: SEND_PAY_PAL_SUBMIT_ORDER_REQUEST,
    payload: {
        orderId,
        payPalTransaction,
    },
});
