import {
    RELOAD_MENU_CARD_REQUEST,
    reloadMenuCardSuccess,
    reloadMenuCardFailure,
    showSuccessNotification,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const reloadMenuCardEpic:TEpic = (action$, store$, { SettingRepository }) => {
    return action$.pipe(
        ofType(RELOAD_MENU_CARD_REQUEST),
        mergeMap(action => from(SettingRepository.reloadCard())
            .pipe(
                mergeMap(() => {
                    return from([
                        showSuccessNotification(`Karte wurde erfolgreich in den Cache geladen.`),
                        reloadMenuCardSuccess(),
                    ]);
                }),
                catchError( error => {
                    return from([
                        reloadMenuCardFailure(error),
                    ])
                })
            )
        ),
    );
}
