import { FIND_REDUCTIONS_REQUEST, findReductionsSuccess, findReductionsFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const findReductionsEpic: TEpic = (action$, store$, { ReductionRepository }) => {
    return action$.pipe(
        ofType( FIND_REDUCTIONS_REQUEST ),
        mergeMap(action => from(
            ReductionRepository.findAll(action.payload)
                .then(({ reductions, meta }) => findReductionsSuccess({ reductions, response: meta, request: action}))
                .catch((error: Error) => findReductionsFailure(error)),
        )),
    );
}
