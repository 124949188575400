import { combineEpics } from 'redux-observable';

import { createIngredientEpic }  from './CreateIngredient/CreateIngredientEpic';
import { deleteIngredientEpic }  from './DeleteIngredient/DeleteIngredientEpic';
import { findIngredientsEpic }   from './FindIngredients/FindIngredientsEpic';
import { findIngredientEpic }    from './FindIngredient/FindIngredientEpic';
import { searchIngredientsEpic } from './SearchIngredients/SearchIngredientsEpic';
import { updateIngredientEpic }  from './UpdateIngredient/UpdateIngredientEpic';


export default combineEpics(
    createIngredientEpic,
    deleteIngredientEpic,
    findIngredientsEpic,
    findIngredientEpic,
    searchIngredientsEpic,
    updateIngredientEpic,
);
