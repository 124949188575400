import { IReduxStore } from "Redux/Store/IReduxStore";
import {
    FIND_ALLERGEN_REQUEST,
    FIND_ALLERGENE_REQUEST,
    SEARCH_ALLERGENE_REQUEST,
    CREATE_ALLERGEN_REQUEST,
    UPDATE_ALLERGEN_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isAllergenLoading = (state: IReduxStore): boolean =>
    ArrayContains(state.allergen.openRequests, [
        FIND_ALLERGEN_REQUEST,
        FIND_ALLERGENE_REQUEST,
        SEARCH_ALLERGENE_REQUEST,
        CREATE_ALLERGEN_REQUEST,
        UPDATE_ALLERGEN_REQUEST,
    ]);