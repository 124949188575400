import { BaseStoreNamespace }  from 'Redux/Store/BaseStore';
import { Option } from "Domain/Model/Setting/Option";
import { Order } from "Domain/Model/Order/Order";

interface IPersistState {
    userData: {
        address: string,
        city: string,
        country: string,
        lat: number,
        lng: number,
        postalCode: string,
        street: string,
        zoom: number,
        name: string,
        phone: string,
        company: string,
        mail: string,
    },
    lastOrder: null|Order
    settings: Record<string, Option>,
}

export type TPersistState = IPersistState & BaseStoreNamespace.TDefaultState;


export const PersistState = {

    getInitialState: (): TPersistState => ({
        updatedAt      : 0,
        openRequests   : [],
        failedRequests : [],
        settings       : {},
        lastOrder:     null,
        userData: {
            address: '',
            city: '',
            country: '',
            lat: 50.9723365,
            lng: 11.030163,
            postalCode: '',
            street: '',
            zoom: 12,
            name: '',
            phone: '',
            company: '',
            mail: ''
        },
    })

}