import { IReduxStore } from "Redux/Store/IReduxStore";
import {
    FIND_MEAL_CATEGORY_REQUEST,
    FIND_MEAL_CATEGORIES_REQUEST,
    FIND_ALL_MEAL_CATEGORIES_REQUEST,
    SEARCH_MEAL_CATEGORIES_REQUEST,
    CREATE_MEAL_CATEGORY_REQUEST,
    UPDATE_MEAL_CATEGORY_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isMealCategoryLoading = (state: IReduxStore): boolean =>
    ArrayContains(state.mealCategory.openRequests, [
        FIND_MEAL_CATEGORY_REQUEST,
        FIND_MEAL_CATEGORIES_REQUEST,
        FIND_ALL_MEAL_CATEGORIES_REQUEST,
        SEARCH_MEAL_CATEGORIES_REQUEST,
        CREATE_MEAL_CATEGORY_REQUEST,
        UPDATE_MEAL_CATEGORY_REQUEST,
    ]);