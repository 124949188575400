import { FIND_SALES_DATA_REQUEST, findSalesDataSuccess, findSalesDataFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const findSalesDataEpic:TEpic = (action$, store$, { OrderRepository }) => {
    return action$.pipe(
        ofType(FIND_SALES_DATA_REQUEST),
        mergeMap( action =>{
            return from(
                OrderRepository.findSalesData()
                    .then(findSalesDataSuccess)
                    .catch(findSalesDataFailure),
            );
        }),
    );
}
