import { IReduxStore } from "Redux/Store/IReduxStore";
import { filterObjectStore, queryFilter, IFilterProps } from 'Redux/Query';
import { Allergen } from "Domain/Model/Allergen/Allergen";

export const getAllergene = (state: IReduxStore, filter: null|IFilterProps<Allergen> = null) => {
    if(filter === null) {
        filter = queryFilter({sorting: { by: 'key', order: 'ASC' }}) as IFilterProps<Allergen>;
    }

    return filterObjectStore(state.allergen.allergen.entries, filter);
};
