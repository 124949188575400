import { BaseStoreNamespace }  from 'Redux/Store/BaseStore';
import { Basket } from "Domain/Model/BasketEntry/BasketEntry";

interface IBasketState {
    basketEntries: Record<string, Basket>,
}

export type TBasketState = IBasketState & BaseStoreNamespace.TDefaultState;


export const BasketState = {

    getInitialState: (): TBasketState => ({
        updatedAt      : 0,
        openRequests   : [],
        failedRequests : [],
        basketEntries  : {},
    })

}