import { IReduxStore } from "Redux/Store/IReduxStore";
import {
    CREATE_INGREDIENT_FAILURE,
    UPDATE_INGREDIENT_FAILURE,
    FIND_INGREDIENT_FAILURE,
    FIND_INGREDIENTS_FAILURE,
    SEARCH_INGREDIENTS_FAILURE,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isIngredientFailed = (state: IReduxStore): boolean => ArrayContains(state.ingredient.failedRequests, [
    CREATE_INGREDIENT_FAILURE,
    UPDATE_INGREDIENT_FAILURE,
    FIND_INGREDIENT_FAILURE,
    FIND_INGREDIENTS_FAILURE,
    SEARCH_INGREDIENTS_FAILURE,
]);