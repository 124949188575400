import {
    CREATE_REDUCTION_REQUEST,
    createReductionSuccess,
    createReductionFailure,
    showSuccessNotification
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const createReductionEpic: TEpic = (action$, store$, { ReductionRepository }) => {
    return action$.pipe(
        ofType(CREATE_REDUCTION_REQUEST),
        mergeMap(action => from(ReductionRepository.create( action.payload ))
            .pipe(
                mergeMap((reduction) => {
                    return from([
                        showSuccessNotification(`Rabatt-Aktion wurde erfolgreich erstellt`),
                        createReductionSuccess( reduction ),
                    ]);
                }),
                catchError( error => {
                    return from([
                        createReductionFailure(error),
                    ])
                })
            )
        ),
    );
}
