import React from "react";
import RowContainer from "./Row.style";
import ColContainer from "./Col.style";

export class Row extends React.Component {

    render() {
        return(
            <RowContainer gutter={16} { ...this.props }>
                { this.props.children }
            </RowContainer>

        );
    }

}

export class Col extends React.Component {

    render() {
        return(
            <ColContainer { ...this.props }>
                { this.props.children }
            </ColContainer>

        );
    }

}