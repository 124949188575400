import { IReduxStore } from "Redux/Store/IReduxStore";
import { getServerOption } from "../GetServerOption/GetServerOption";

export interface IAddressPositionEntry {
    address: string,
    city: string,
    country: string,
    lat: number,
    lng: number,
    postalCode: string,
    street: string,
    zoom: number,
}

export interface IUserData {
    name: string,
    company: string,
    phone: string,
    mail: string,
}

export type TUserData = IAddressPositionEntry & IUserData

export const getDeliveryAddress = (state: IReduxStore): TUserData => {
    const userData      = { ...state.persist.userData } as TUserData;
    const storePosition = getServerOption(state, 'companyLocation', null) as null|IAddressPositionEntry;

    if(`${userData.address}`.trim() === '' && storePosition !== null && `${storePosition.address}`.trim() !== '') {
        userData.lat = storePosition.lat;
        userData.lng = storePosition.lng;
        userData.zoom = storePosition.zoom;
    }

    return userData;
}