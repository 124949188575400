import { Allergen } from "Domain/Model/Allergen/Allergen";

export const FIND_ALLERGENE_SUCCESS = 'FIND_ALLERGENE_SUCCESS';

interface IFindAllergeneSuccess {
    allergene: Record<string, Allergen>,
    request: {},
    response: {},
}

export const findAllergeneSuccess = ({ allergene, request, response }: IFindAllergeneSuccess) => ({
    type: FIND_ALLERGENE_SUCCESS,
    payload: { allergene },
    meta: {
        request,
        response,
    }
});
