import BaseRepository from 'Domain/Repository/BaseRepository/BaseRepository';
import { Meal } from 'Domain/Model/Meal/Meal';

const config = {
    model: Meal,
    baseRoute: '/meal',
    singular: 'meal',
    plural: 'meals',
}

class MealRepository extends BaseRepository<Meal, typeof config.singular, typeof config.plural> {

}

export default new MealRepository(config);
