import { FIND_ORDERS_REQUEST, findOrdersSuccess, findOrdersFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const findOrdersEpic: TEpic = (action$, store$, { OrderRepository }) => {
    return action$.pipe(
        ofType( FIND_ORDERS_REQUEST ),
        mergeMap(action => from(
            OrderRepository.findAll(action.payload)
                .then(({ orders, meta }) => findOrdersSuccess({ orders, response: meta, request: action}))
                .catch((error: Error) => findOrdersFailure(error)),
        )),
    );
}
