import { BaseStoreNamespace } from "Redux/Store/BaseStore"
import { Ingredient } from "Domain/Model/Ingredient/Ingredient";

interface IIngredientState {
    ingredient: {
        entries: Record<string, Ingredient>
        maxCount: number;
    },
    searchEntries: Record<string, Ingredient>,
    lastInteractiveId: null|string|number,
}

export type TIngredientState = IIngredientState & BaseStoreNamespace.TDefaultState;


export const IngredientState = {
    getInitialState: (): TIngredientState => ({
        openRequests:   [],
        failedRequests: [],
        updatedAt:      0,
        searchEntries:  {},
        ingredient: {
            entries: {},
            maxCount: 0,
        },
        lastInteractiveId: null,
    })
}