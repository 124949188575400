import { IReduxStore } from "Redux/Store/IReduxStore";
import { filterObjectStore, queryFilter, IFilterProps } from 'Redux/Query';
import { Reduction } from "Domain/Model/Reduction/Reduction";

export const getActiveReductions = (state: IReduxStore, filter: null|IFilterProps<Reduction> = null) => {
    if(filter === null) {
        filter = queryFilter({sorting: { by: 'name', order: 'DESC' }}) as IFilterProps<Reduction>;
    }

    const reductions = filterObjectStore(state.reduction.reduction.entries, filter);

    return reductions.filter(reduction => {
        const now = new Date();
        const day = reduction.days[now.getDay() === 0 ? 6 : now.getDay() - 1];

        if(day.wholeDay) { return true }

        if(day.startAt !== null && day.startAt > now) {
            return false;
        }

        return !(day.endAt !== null && day.endAt < now);
    });
};
