import { IReduxStore } from "Redux/Store/IReduxStore";
import {
    SEND_ORDER_REQUEST,
    FIND_ORDER_REQUEST,
    UPDATE_ORDER_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isOrderLoading = (state: IReduxStore): boolean =>
    ArrayContains(state.order.openRequests, [
        SEND_ORDER_REQUEST,
        FIND_ORDER_REQUEST,
        UPDATE_ORDER_REQUEST,
    ]);