import { SEARCH_ALLERGENE_REQUEST, searchAllergeneSuccess, searchAllergeneFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const searchAllergeneEpic: TEpic = (action$, store$, { AllergenRepository }) => {
    return action$.pipe(
        ofType( SEARCH_ALLERGENE_REQUEST ),
        mergeMap(action => from(
            AllergenRepository.findAll(action.payload)
                .then(({ allergene, meta }) => searchAllergeneSuccess({ allergene, response: meta, request: action}))
                .catch((error: Error) => searchAllergeneFailure(error)),
        )),
    );
}
