import { FIND_ORDER_REQUEST, findOrderSuccess, findOrderFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const findOrderEpic:TEpic = (action$, store$, { OrderRepository }) => {
    return action$.pipe(
        ofType(FIND_ORDER_REQUEST),
        mergeMap( action =>{
            const { id } = action.payload;

            if(!id.trim()) {
                return EMPTY;
            }

            return from(
                OrderRepository.findById( id )
                    .then(orders => findOrderSuccess(orders))
                    .catch(error => findOrderFailure(error)),
            );
        }),
    );
}
