import { LOAD_MENU_CARD_REQUEST, loadMenuCardSuccess, loadMenuCardFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const loadMenuCardEpic:TEpic = (action$, store$, { SettingRepository }) => {
    return action$.pipe(
        ofType(LOAD_MENU_CARD_REQUEST),
        mergeMap( () =>{
            return from(
                SettingRepository.loadCard()
                    .then((card) => loadMenuCardSuccess(card))
                    .catch((error: Error) => loadMenuCardFailure(error)),
            );
        }),
    );
}
