import {
    SEND_ORDER_REQUEST,
    sendOrderSuccess,
    sendOrderFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const sendOrderEpic: TEpic = (action$, store$, { OrderRepository }) => {
    return action$.pipe(
        ofType(SEND_ORDER_REQUEST),
        mergeMap(action => from(OrderRepository.send( action.payload ))
            .pipe(
                mergeMap((order) => {
                    return from([
                        sendOrderSuccess( order ),
                    ]);
                }),
                catchError( error => {
                    return from([
                        sendOrderFailure(error),
                    ])
                })
            )
        ),
    );
}
