import { IFilterSettings } from "Domain/Repository/BaseRepository/BaseRepository";

export const SEARCH_REDUCTIONS_REQUEST = 'SEARCH_REDUCTIONS_REQUEST';

export const searchReductionsRequest = ({
     filter = [],
     sorting = { field: 'id', order: 'ASC' },
     pagination = { page: 1, take: 50 },
 }: IFilterSettings) => ({
    type: SEARCH_REDUCTIONS_REQUEST,
    payload: { filter, sorting, pagination },
    meta: {
        sorting,
    }
});
