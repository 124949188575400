import { IReduxStore } from "Redux/Store/IReduxStore";
import {
    CREATE_MEAL_FAILURE,
    UPDATE_MEAL_FAILURE,
    FIND_MEAL_FAILURE,
    FIND_MEALS_FAILURE,
    SEARCH_MEALS_FAILURE,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isMealFailed = (state: IReduxStore): boolean => ArrayContains(state.meal.failedRequests, [
    CREATE_MEAL_FAILURE,
    UPDATE_MEAL_FAILURE,
    FIND_MEAL_FAILURE,
    FIND_MEALS_FAILURE,
    SEARCH_MEALS_FAILURE,
]);