import React from 'react';
import ButtonStyle from './Button.style';
import { Tooltip } from 'antd';
import { Subject } from "rxjs";
import { ButtonType } from "antd/lib/button";

type TProps = {
    subject: Subject<any>|null,
    onClick: () => unknown,
    type?: ButtonType,
    label?: React.ReactElement|string|null,
    title?: React.ReactElement|string|null,
    htmlType?: "button" | "submit" | "reset",
    className?: string,
    disabled?: boolean,
    danger?: boolean,
    style?: any,
}

export default class Button extends React.Component<TProps, {}> {

    static defaultProps = {
        type: 'primary',
        onClick: () => {},
        subject: null,
        label: null,
        title: null,
    }

    handleClick = () => {
        if(this.props.subject) {
            this.props.subject.next();
        }
        this.props.onClick();
    };

    renderButton() {
        const { htmlType, ...props } = this.props;
        return (
            <ButtonStyle
                htmlType={htmlType}
                { ...props }
                title={ '' }
                onClick={this.handleClick}
            >{ this.props.children || this.props.label }</ButtonStyle>
        );
    }

    render() {
        if(this.props.title) {
            return (
                <Tooltip title={this.props.title} >
                    { this.renderButton() }
                </Tooltip>
            );
        }
        return this.renderButton();
    }

}