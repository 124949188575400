import { Meal, IMeal } from "Domain/Model/Meal/Meal";
import { IMealSelection, MealSelection } from "Domain/Model/MealSelection/MealSelection";
import { IIngredient, Ingredient } from "Domain/Model/Ingredient/Ingredient";
import { BaseModel } from "Domain/Model//BaseModel/BaseModel";

export interface IMealCategory {
    id:           string,
    name:         string,
    tax:          number,
    description?: string,
    meals?:       IMeal[],
    selections?:  IMealSelection[],
    ingredients?: IIngredient[],
    sorting?:     number,
    pricesOnlyToIngredients: boolean,
}

export class MealCategory extends BaseModel {

    public id:           string;
    public name:         string;
    public tax:          number;
    public meals?:       Meal[];
    public description?: string;
    public selections:   MealSelection[];
    public ingredients:  Ingredient[];
    public sorting:      number;
    public pricesOnlyToIngredients: boolean;

    constructor(data: IMealCategory) {
        super();

        this.id          = data.id;
        this.name        = data.name;
        this.tax         = data.tax || 7;
        this.description = data.description;
        this.meals       = (data.meals) ? data.meals.map(entry => new Meal(entry)) : [];
        this.selections  = (data.selections) ? data.selections.map(entry => new MealSelection(entry)) : [];
        this.ingredients = (data.ingredients) ? data.ingredients.map(entry => new Ingredient(entry)) : [];
        this.sorting     = data.sorting || 0;
        this.pricesOnlyToIngredients = data.pricesOnlyToIngredients;

        this.meals = this.meals.sort(( item1, item2 ) =>
            (item1.iNumber  < item2.iNumber) ? -1 : (item1.iNumber > item2.iNumber) ? 1 : 0);
        this.ingredients = this.ingredients.sort(( item1, item2 ) =>
            (item1.name  < item2.name) ? -1 : (item1.name > item2.name) ? 1 : 0);

    }
}