import { combineEpics } from 'redux-observable';

import { createMealEpic }  from './CreateMeal/CreateMealEpic';
import { deleteMealEpic }  from './DeleteMeal/DeleteMealEpic';
import { findMealsEpic }   from './FindMeals/FindMealsEpic';
import { findMealEpic }    from './FindMeal/FindMealEpic';
import { searchMealsEpic } from './SearchMeals/SearchMealsEpic';
import { updateMealEpic }  from './UpdateMeal/UpdateMealEpic';


export default combineEpics(
    createMealEpic,
    deleteMealEpic,
    findMealsEpic,
    findMealEpic,
    searchMealsEpic,
    updateMealEpic,
);
