import { MealCategory } from "Domain/Model/MealCategory/MealCategory";

export const SEARCH_MEAL_CATEGORIES_SUCCESS = 'SEARCH_MEAL_CATEGORIES_SUCCESS';

interface IFindMealCategoriesSuccess {
    mealCategories: Record<string, MealCategory>,
    request: {},
    response: {},
}

export const searchMealCategoriesSuccess = ({ mealCategories, request, response }: IFindMealCategoriesSuccess) => ({
    type: SEARCH_MEAL_CATEGORIES_SUCCESS,
    payload: { mealCategories },
    meta: {
        request,
        response,
    }
});
