import React from "react";
import OuterContainer from "./PopOver.style";
import { Subject } from "rxjs";
import { debounceTime, delay, takeUntil } from "rxjs/operators";
import { CloseOutlined } from "@ant-design/icons";

export default class PopOver extends React.Component {

    state = {
        visible: false,
    }

    onMount$    = new Subject();
    onClose$    = new Subject();
    onDestroy$  = new Subject();

    componentDidMount() {
        this.onMount$.pipe(takeUntil(this.onDestroy$), delay(25)).subscribe(() => {
            this.setState(() => ({ visible: true }))
        });
        this.onClose$.pipe(takeUntil(this.onDestroy$), debounceTime(350)).subscribe(() => {
            if(this.props.hasOwnProperty('onClose') && typeof this.props.onClose === 'function') {
                this.props.onClose();
            }
        });

        this.onMount$.next();
    }

    closePopOver() {
        this.handleOnClose();
    }


    componentWillUnmount() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    handleOnClose = () => {
        this.setState(
            () => ({ visible: false }),
            () => { this.onClose$.next(); }
        );
    }

    renderHeadline = () => {
        const { headline } = this.props;
        if(!headline) {
            return null;
        }
        return (
            <div className="contentHeadline">
                { headline }
            </div>
        );
    }

    renderActions = () => {
        if(!this.props.actions) {
            return null;
        }
        return (
            <div className="controls">
                { this.props.actions }
            </div>
        );
    }


    render() {
        const { children, className, showCloseButton = true } = this.props;
        return (
            <OuterContainer className={ `${(className) ? className : ''} ${(this.state.visible) ? 'show' : ''}` }>
                <div className={ `contentContainer ${(!!this.props.actions) ? 'hasControls' : ''}` }>
                    { this.renderHeadline() }
                    { (showCloseButton) ? <CloseOutlined className="closePopOver" onClick={ this.handleOnClose } /> : null }
                    <div className="contentWrapper">
                        { children }
                    </div>
                    { this.renderActions() }
                </div>

            </OuterContainer>
        );
    }

}