import { BaseStoreNamespace } from "Redux/Store/BaseStore"
import { MealCategory } from "Domain/Model/MealCategory/MealCategory";

interface IMealCategoryState {
    mealCategory: {
        entries: Record<string, MealCategory>
        maxCount: number;
    },
    searchEntries: Record<string, MealCategory>,
    lastInteractiveId: null|string|number,
}

export type TMealCategoryState = IMealCategoryState & BaseStoreNamespace.TDefaultState;


export const MealCategoryState = {
    getInitialState: (): TMealCategoryState => ({
        openRequests:   [],
        failedRequests: [],
        updatedAt:      0,
        searchEntries:  {},
        mealCategory: {
            entries: {},
            maxCount: 0,
        },
        lastInteractiveId: null,
    })
}