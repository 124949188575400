import {
    UPDATE_MEAL_CATEGORY_REQUEST,
    updateMealCategorySuccess,
    updateMealCategoryFailure,
    showSuccessNotification
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const updateMealCategoryEpic: TEpic = (action$, store$, { MealCategoryRepository }) => {
    return action$.pipe(
        ofType(UPDATE_MEAL_CATEGORY_REQUEST),
        mergeMap(action => from(MealCategoryRepository.update( action.payload.id, action.payload.updateData ))
            .pipe(
                mergeMap((ingredient) => {
                    return from([
                        showSuccessNotification(`Die Speicherung der Produkt Kategorie erfolgreich.`),
                        updateMealCategorySuccess(ingredient),
                    ]);
                }),
                catchError( error => {
                    return from([
                        updateMealCategoryFailure(error)
                    ])
                })
            )
        ),
    );
}
