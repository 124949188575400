import { MealCategory } from "Domain/Model/MealCategory/MealCategory";
import { Ingredient }   from "Domain/Model/Ingredient/Ingredient";
import { Allergen }     from "Domain/Model/Allergen/Allergen";
import { Meal }         from "Domain/Model/Meal/Meal";

export const LOAD_MENU_CARD_SUCCESS = 'LOAD_MENU_CARD_SUCCESS';

interface IPayload {
    mealCategories: Record<string, MealCategory>,
    ingredients:    Record<string, Ingredient>,
    allergens:      Record<string, Allergen>,
    meals:          Record<string, Meal>,
}

export const loadMenuCardSuccess = (payload: IPayload) => ({
    type: LOAD_MENU_CARD_SUCCESS,
    payload,
});