import { BaseModel } from "Domain/Model/BaseModel/BaseModel";

export interface IAllergen {
    id:          string,
    name:        string,
    key:         string,
    description: string,
}

export class Allergen extends BaseModel {

    public id:          string;
    public name:        string;
    public key:         string;
    public description: string;


    constructor(data: IAllergen) {
        super();

        this.id          = data.id;
        this.name        = data.name;
        this.description = data.description;
        this.key         = data.key;

    }

}