import { IReduxStore } from 'Redux/Store/IReduxStore';

export const isGlobalStateEqual = (prevStore: IReduxStore, nextStore: IReduxStore) =>
    prevStore.global.updatedAt === nextStore.global.updatedAt;
export const isAllergenStateEqual = (prevStore: IReduxStore, nextStore: IReduxStore) =>
    prevStore.allergen.updatedAt === nextStore.allergen.updatedAt;
export const isIngredientStateEqual = (prevStore: IReduxStore, nextStore: IReduxStore) =>
    prevStore.ingredient.updatedAt === nextStore.ingredient.updatedAt;
export const isMealCategoryStateEqual = (prevStore: IReduxStore, nextStore: IReduxStore) =>
    prevStore.mealCategory.updatedAt === nextStore.mealCategory.updatedAt;
export const isMealStateEqual = (prevStore: IReduxStore, nextStore: IReduxStore) =>
    prevStore.meal.updatedAt === nextStore.meal.updatedAt;
export const isReductionStateEqual = (prevStore: IReduxStore, nextStore: IReduxStore) =>
    prevStore.reduction.updatedAt === nextStore.reduction.updatedAt;
export const isOrderStateEqual = (prevStore: IReduxStore, nextStore: IReduxStore) =>
    prevStore.order.updatedAt === nextStore.order.updatedAt;
