import { IReduxStore } from "Redux/Store/IReduxStore";
import {
    CREATE_MEAL_CATEGORY_FAILURE,
    UPDATE_MEAL_CATEGORY_FAILURE,
    FIND_MEAL_CATEGORY_FAILURE,
    FIND_MEAL_CATEGORIES_FAILURE,
    SEARCH_MEAL_CATEGORIES_FAILURE,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isMealCategoryFailed = (state: IReduxStore): boolean => ArrayContains(state.mealCategory.failedRequests, [
    CREATE_MEAL_CATEGORY_FAILURE,
    UPDATE_MEAL_CATEGORY_FAILURE,
    FIND_MEAL_CATEGORY_FAILURE,
    FIND_MEAL_CATEGORIES_FAILURE,
    SEARCH_MEAL_CATEGORIES_FAILURE,
]);