import { IReduxStore } from "Redux/Store/IReduxStore";
import {
    CREATE_REDUCTION_FAILURE,
    UPDATE_REDUCTION_FAILURE,
    FIND_REDUCTION_FAILURE,
    FIND_REDUCTIONS_FAILURE,
    SEARCH_REDUCTIONS_FAILURE,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isReductionFailed = (state: IReduxStore): boolean => ArrayContains(state.reduction.failedRequests, [
    CREATE_REDUCTION_FAILURE,
    UPDATE_REDUCTION_FAILURE,
    FIND_REDUCTION_FAILURE,
    FIND_REDUCTIONS_FAILURE,
    SEARCH_REDUCTIONS_FAILURE,
]);