import {
    CREATE_MEAL_REQUEST,
    createMealSuccess,
    createMealFailure,
    showSuccessNotification
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const createMealEpic: TEpic = (action$, store$, { MealRepository }) => {
    return action$.pipe(
        ofType(CREATE_MEAL_REQUEST),
        mergeMap(action => from(MealRepository.create( action.payload ))
            .pipe(
                mergeMap((meal) => {
                    return from([
                        showSuccessNotification(`Gericht wurde erfolgreich erstellt`),
                        createMealSuccess( meal ),
                    ]);
                }),
                catchError( error => {
                    return from([
                        createMealFailure(error),
                    ])
                })
            )
        ),
    );
}
