import {IReduxStore} from "Redux/Store/IReduxStore";

interface IStatsEntry {
    count: number,
    total: string,
    payment_method: string,
    shipping_method: string,
}

export const getSalesData = (state: IReduxStore) => {
    const stats = state.order.sales.stats;
    const data: any  = {
        sales:        { total: 0, year: 0, month: 0, day: 0 },
        salesPayPal:  { total: 0, year: 0, month: 0, day: 0 },
        salesCash:    { total: 0, year: 0, month: 0, day: 0 },
        salesDeliver: { total: 0, year: 0, month: 0, day: 0 },
        salesPickUp:  { total: 0, year: 0, month: 0, day: 0 },

        orders:        { total: 0, year: 0, month: 0, day: 0 },
        ordersPayPal:  { total: 0, year: 0, month: 0, day: 0 },
        ordersCash:    { total: 0, year: 0, month: 0, day: 0 },
        ordersDeliver: { total: 0, year: 0, month: 0, day: 0 },
        ordersPickUp:  { total: 0, year: 0, month: 0, day: 0 },

    }

    const setData = (key: string) => {
        stats[key].forEach((entry: IStatsEntry) => {
            data.sales[key] += parseFloat(entry.total);
            data.orders[key] += entry.count;
            if(entry.shipping_method === 'Deliver') {
                data.salesDeliver[key] += parseFloat(entry.total);
                data.ordersDeliver[key] += entry.count;
            }
            if(entry.shipping_method === 'PickUp') {
                data.salesPickUp[key] += parseFloat(entry.total);
                data.ordersPickUp[key] += entry.count;
            }
            if(entry.payment_method === 'PayPal') {
                data.salesPayPal[key] += parseFloat(entry.total);
                data.ordersPayPal[key] += entry.count;
            }
            if(entry.payment_method === 'Cash') {
                data.salesCash[key] += parseFloat(entry.total);
                data.ordersCash[key] += entry.count;
            }

        })
    }

    setData('total');
    setData('year');
    setData('month');
    setData('day');

    return data;
}