import { IReduxStore } from "Redux/Store/IReduxStore";
import isEmpty from "lodash/isEmpty";
import { isHoliday, getServerOption } from "Redux/Query";

export interface IOpeningTime {
    label: string,
    timeString: string|null,
    open: boolean,
    openingDay: IDayOpening|null,
    disableDelivery?: boolean,
    willOpenToDay?: boolean,
}

export interface IDayOpening {
    closed: boolean,
    times: [string, string][]
}

export interface IStoreOpening {
    mon: IDayOpening,
    tue: IDayOpening,
    wed: IDayOpening,
    thu: IDayOpening,
    fri: IDayOpening,
    sat: IDayOpening,
    sun: IDayOpening,
    holiday: IDayOpening,
}

const days = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];

const getSeconds = (times: string[]) => {
    if(times.length === 3) {
        return (parseInt(times[0], 10) * 60 * 60) + (parseInt(times[1], 10) * 60) + (parseInt(times[2], 10));
    }
    if(times.length === 2) {
        return (parseInt(times[0], 10) * 60 * 60) + (parseInt(times[1], 10) * 60);
    }
    if(times.length === 1) {
        return (parseInt(times[0], 10) * 60 * 60);
    }
    return 0;

};

export const getUnifyDay = () => {
    const day = new Date();
    day.setMilliseconds(0);
    day.setSeconds(0);
    day.setMinutes(0);
    day.setHours(0);

    return day;
};

export const getOpeningTime = (state: IReduxStore, day: string|null = null, ignoreHoliday = false): IOpeningTime => {
    const storeOpenings = getServerOption(state, 'openingHours', null) as (IStoreOpening|null);
    if(storeOpenings === null || isEmpty(storeOpenings)) {
        return { label: 'heute Geschlossen', timeString: null, open: false, openingDay: null };
    }

    const today = (() => {
        const day       = new Date();
        return {
            shortName: day.toString().split(' ')[0].toLowerCase(),
            dayName:  days[day.getDay()]
        };
    })();

    if(day === null) {
        day = today.shortName;
    }

    let openingDay = (storeOpenings as any)[day] as IDayOpening;
    if(isHoliday(state) && day === today.shortName && !ignoreHoliday) {
        openingDay = storeOpenings.holiday as IDayOpening;
    }
    if(!openingDay || openingDay.closed) {
        return { label: 'heute Geschlossen', timeString: null, open: false, openingDay };
    }

    const time = Math.round((new Date().getTime() - getUnifyDay().getTime()) / 1000);


    for(let i = 0, l = openingDay.times.length; i < l; i++) {
        const times = openingDay.times[i];
        // @ts-ignore
        const timeArray = [getSeconds((times[0]).split(':')), getSeconds(times[1].split(':'))];
        if(time < timeArray[0]) {
            return { label: 'Geschlossen, öffnet heute %s', timeString: times[0], open: false, openingDay, willOpenToDay: true };
        }

        // 14:00 - 01:00 -> opening over 12pm
        if(timeArray[0] >= timeArray[1]) {
            timeArray[1] += 86400;
        }
        if(time >= timeArray[0] && time <= timeArray[1]) {
            const disableDelivery = time > (timeArray[1] - (getSeconds(`${getServerOption(state, 'maxOrderTime', '00:00')}`.split(':'))));
            return {
                label: disableDelivery ? `Schließt bald! Noch bis %s geöffnet` : 'Heute bis %s geöffnet',
                timeString: times[1],
                open: true,
                openingDay,
                disableDelivery,
            };
        }
    }

    return { label: 'heute Geschlossen', timeString: null, open: false, openingDay };
}