import styled from 'styled-components';
import { Button } from 'antd';
import { fontColor, buttonPrimaryBgColor, white, borderHoverColor } from 'Public/css/colors';

export default styled(Button) `
  display: inline-block;
  background-color: ${buttonPrimaryBgColor};
  border-color: ${buttonPrimaryBgColor};
  color: ${white};
  margin-bottom: 8px;
  height: 40px;
  font-size: 1.2rem;
  line-height: 1;
  padding: 5px 10px;
  
  &:hover, &:focus {
      background-color: ${borderHoverColor};
      border-color:  ${borderHoverColor};
  }
    
  &.noneStyle {
      background-color: transparent;
      border: none;
      padding: 0;
      box-shadow: none;
      margin: 0;
      height: auto;
      display: inline;;
  }

  &[disabled], &[disabled]:hover {
    opacity: 0.7;
  }

  a {
    color: ${white};
  }

  &.warning {
    color: #fa8c16;
    background: #fff7e6;
    border-color: #ffd591;

    &:hover {
      color: #fa8c16;
      background: #fff7e6;
      border-color: #ffd591;
    }

    &:hover:not([disabled]) {
      background: #fa8c16;
      color: #fff7e6;

    }
  }

  &.error {
    color: #e1001a;
    background: #fff7e6;
    border-color: #f37480;

    &:hover {
      color: #e1001a;
      background: #fff7e6;
      border-color: #f37480;
    }

    &:hover:not([disabled]) {
      background: #e1001a;
      color: #fff7e6;

    }
  }

  &.ant-btn-secondary, &.create {
    background-color: transparent;
    color: ${fontColor};
    border-color: ${fontColor};

    a {
      color: ${fontColor};
    }

    &:hover {
      color: ${buttonPrimaryBgColor};
      border-color: ${buttonPrimaryBgColor};

    }

    &.warning {
      color: #fa8c16;
      border-color: #ffd591;

      &:hover {
        color: #fa8c16;
        border-color: #ffd591;
      }

      &:hover:not([disabled]) {
        background: #fa8c16;
        color: #fff7e6;

      }
    }
    
  }

  &.create {
    color: ${buttonPrimaryBgColor};
    font-weight: bold;
    border-color: ${buttonPrimaryBgColor};
    box-shadow: none;
    background-color: ${white};
    margin-left: 20px;
  }

  &.small {
    height: 20px;
    padding: 0 10px;
    margin-bottom: 0;
    line-height: 1;
  }
    
    &.ant-btn-default {
        background-color: transparent;
        border: solid 1px #414141;
        color: #414141;
    }
`;