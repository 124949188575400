import BaseRepository from 'Domain/Repository/BaseRepository/BaseRepository';
import { Allergen } from 'Domain/Model/Allergen/Allergen';

const config = {
    model: Allergen,
    baseRoute: '/allergen',
    singular: 'allergen',
    plural: 'allergene',
}

class AllergenRepository extends BaseRepository<Allergen, typeof config.singular, typeof config.plural> {

}

export default new AllergenRepository(config);
