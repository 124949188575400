import React from "react";
import {
    LoadingOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,
    LogoutOutlined,
    SaveOutlined,
    SearchOutlined,
    CloseOutlined,
    ClockCircleOutlined,
    EditOutlined,
    DeleteOutlined,
    CheckCircleOutlined,
    PlusOutlined,
    ReloadOutlined,
    WarningOutlined,
    InfoCircleOutlined,
    ShoppingCartOutlined,
    UserOutlined,
    LockOutlined,
    AppstoreOutlined,
    DeploymentUnitOutlined,
    FolderOutlined,
    PieChartOutlined,
    FolderAddOutlined,
    GoldOutlined,
    DragOutlined,
    CoffeeOutlined,
    FallOutlined,
    CalendarOutlined,
    SettingOutlined,
    AimOutlined,
    ArrowLeftOutlined,
    PhoneOutlined,
    BookOutlined,
    DownloadOutlined,
    PictureOutlined,
    MoreOutlined,
    ArrowsAltOutlined,
    RedoOutlined,
    UndoOutlined,
    FilePdfOutlined,
} from "@ant-design/icons";

type TProps = {
    type: string,
    onClick?: (evt: any) => unknown,
    className?: string,
}

export default class Icon extends React.Component<TProps, {}> {

    static defaultProps  = {
        type: '',
        onClick: () => {},
    }

    render() {
        const { type, ...props } = this.props;

        switch( type ) {
            case 'user'          : return <UserOutlined { ...props } />;
            case 'lock'          : return <LockOutlined { ...props } />;
            case 'edit'          : return <EditOutlined { ...props } />;
            case 'loading'       : return <LoadingOutlined { ...props } />;
            case 'eye'           : return <EyeOutlined { ...props } />;
            case 'eye-invisible' : return <EyeInvisibleOutlined { ...props } />;
            case 'logout'        : return <LogoutOutlined { ...props } />;
            case 'save'          : return <SaveOutlined { ...props } />;
            case 'search'        : return <SearchOutlined { ...props } />;
            case 'close'         : return <CloseOutlined { ...props } />;
            case 'clock-circle'  : return <ClockCircleOutlined { ...props } />;
            case 'delete'        : return <DeleteOutlined { ...props } />;
            case 'check'         : return <CheckCircleOutlined { ...props } />;
            case 'plus'          : return <PlusOutlined { ...props } />;
            case 'warning'       : return <WarningOutlined { ...props } />;
            case 'reload'        : return <ReloadOutlined { ...props } />;
            case 'info-circle'   : return <InfoCircleOutlined { ...props } />;
            case 'check-circle'  : return <CheckCircleOutlined { ...props } />;
            case 'cart'          : return <ShoppingCartOutlined { ...props } />;
            case 'appstore'      : return <AppstoreOutlined { ...props } />;
            case 'allergene'     : return <DeploymentUnitOutlined { ...props } />;
            case 'ingredient'    : return <PieChartOutlined { ...props } />;
            case 'folder'        : return <FolderOutlined { ...props } />;
            case 'selections'    : return <GoldOutlined { ...props } />;
            case 'folderAdd'     : return <FolderAddOutlined { ...props } />;
            case 'drag'          : return <DragOutlined { ...props } />;
            case 'coffee'        : return <CoffeeOutlined { ...props } />;
            case 'options'       : return <FolderAddOutlined { ...props } />;
            case 'reduction'     : return <FallOutlined { ...props } />;
            case 'calender'      : return <CalendarOutlined { ...props } />;
            case 'setting'       : return <SettingOutlined { ...props } />;
            case 'aim'           : return <AimOutlined { ...props } />;
            case 'arrow-left'    : return <ArrowLeftOutlined { ...props } />;
            case 'phone'         : return <PhoneOutlined { ...props } />;
            case 'order'         : return <BookOutlined { ...props } />;
            case 'download'      : return <DownloadOutlined { ...props } />;
            case 'picture'       : return <PictureOutlined { ...props } />;
            case 'more'          : return <MoreOutlined { ...props } />;
            case 'arrows-alt'    : return <ArrowsAltOutlined { ...props } />;
            case 'redo'          : return <RedoOutlined { ...props } />;
            case 'undo'          : return <UndoOutlined { ...props } />;
            case 'pdf'           : return <FilePdfOutlined { ...props } />;

            default : return `No Icon found: (${type})`;
            // no default
        }
    }

}