import {IReduxStore} from "Redux/Store/IReduxStore";

export interface IDaySalesEntry {
    sort: number,
    name: string,
    total: number,
    month: string,
    lastMonth: string,
    count: number,
    lastTotal: number,
    lastCount: number,
}

const monthLabels = [
    'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli',
    'August', 'September', 'Oktober', 'November', 'Dezember',
]


export const getMonthSalesData = (state: IReduxStore): IDaySalesEntry[] => {
    const now  = new Date();
    const date = new Date();
    date.setMonth(date.getMonth() - 1);

    const days: Record<string, IDaySalesEntry> = {};
    const { month, lastMonth } = state.order.sales;

    for(let i = 1; i <= 31; i++) {
        const day = `${i}`.padStart(2, '0');
        days[day] = { sort: i, name: day, month: monthLabels[now.getMonth()], lastMonth: monthLabels[date.getMonth()], total: 0, count: 0, lastTotal: 0, lastCount: 0, };

    }


    month.forEach((entry: any) => {
        days[entry.d].total = entry.total;
        days[entry.d].count = entry.count;
    });
    lastMonth.forEach((entry: any) => {
        days[entry.d].lastTotal = entry.total;
        days[entry.d].lastCount = entry.count;
    });

    return Object.keys(days).map(d => days[d]).sort((a,b) => a.sort - b.sort);
}