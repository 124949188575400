import { GlobalState, TGlobalState } from "Redux/Store/Global/GlobalState";
import { Reducer } from "redux";
import { message } from "antd";
import {
    LOGOUT_USER,
    APPLICATION_BOOTSTRAPPED,

    APPEAR_PAGE,

    LOAD_MENU_CARD_REQUEST,
    LOAD_MENU_CARD_SUCCESS,
    LOAD_MENU_CARD_FAILURE,

    SCROLL_TO,

    SHOW_NOTIFICATION,
} from "Redux/Action";
import { linkHandler } from "ClientService"
import { removeOpenRequest, removeFailedRequest, getSingleObject } from 'ClientService/ReducerUtil';
import {MessageApi} from "antd/lib/message";
const initialState = GlobalState.getInitialState();

export const globalReducer: Reducer<TGlobalState> = ( state = initialState, action ) => {
    switch( action.type ) {
        case APPLICATION_BOOTSTRAPPED:
            return {
                ...state,
                updatedAt: Date.now(),
                isApplicationBootstrapped: true
            };

        case APPEAR_PAGE:
            const route: any = linkHandler.findByPageKey(action.payload.page);

            return {
                ...state,
                activeSideMenuTab: route?.parent || route?.key,
                updatedAt: Date.now(),
            }


        case SCROLL_TO:
            return {
                ...state,
                updatedAt: Date.now(),
                activeCategory: action.payload.categoryName,
            };

        case LOAD_MENU_CARD_REQUEST:
            return {
                ...state,
                lastInteractiveId: null,
                openRequests: [...state.openRequests, action.type],
                failedRequests: removeFailedRequest(action, state),
                updatedAt: Date.now(),
            };

        case LOAD_MENU_CARD_SUCCESS:
            const entry = getSingleObject(action.payload);
            return {
                ...state,
                lastInteractiveId: entry.id,
                openRequests: removeOpenRequest(action, state),
                updatedAt: Date.now(),
            };

        case SHOW_NOTIFICATION:
            message[action.payload.notificationType as keyof MessageApi](action.payload.message)
            return {
                ...state,
                updatedAt: Date.now(),
            };

        case LOGOUT_USER:
            return initialState;


        case LOAD_MENU_CARD_FAILURE:
            return {
                ...state,
                openRequests: removeOpenRequest(action, state),
                failedRequests: [...state.failedRequests, action.type],
                updatedAt: Date.now(),
            };


        default:
            return state;
    }
};