import { combineEpics }       from "redux-observable";
import { globalSettingsEpic } from "./GlobalSettings/GlobalSettingsEpic";
import { getSettingsEpic }    from "./GetSettings/GetSettingsEpic";
import { reloadServerSettingsEpic }        from "./ReloadServerSettings/ReloadServerSettingsEpic";
import { updateServerSettingsRequestEpic } from "./UpdateServerSettings/UpdateServerSettingsRequestEpic";
import { loadMenuCardEpic } from "./LoadMenuCard/LoadMenuCardEpic";
import { reloadMenuCardEpic } from "./ReloadMenuCard/ReloadMenuCardEpic";


export default combineEpics(
    globalSettingsEpic,
    getSettingsEpic,
    reloadServerSettingsEpic,
    updateServerSettingsRequestEpic,
    loadMenuCardEpic,
    reloadMenuCardEpic,
)