import { FIND_ALLERGENE_REQUEST, findAllergeneSuccess, findAllergeneFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const findAllergeneEpic: TEpic = (action$, store$, { AllergenRepository }) => {
    return action$.pipe(
        ofType( FIND_ALLERGENE_REQUEST ),
        mergeMap(action => from(
            AllergenRepository.findAll(action.payload)
                .then(({ allergene, meta }) => findAllergeneSuccess({ allergene, response: meta, request: action}))
                .catch((error: Error) => findAllergeneFailure(error)),
        )),
    );
}
