import { BasketState, TBasketState } from "Redux/Store/Basket/BasketState";
import { Reducer } from "redux";
import {

    ADD_TO_BASKET,
    CHANGE_AMOUNT_FROM_BASKET_ENTRY,
    DELETE_BASKET_ENTRY,

    SEND_ORDER_SUCCESS,
    CLEAR_ORDER,

    APPLICATION_BOOTSTRAPPED, CHANGE_BASKET_ENTRY_TOTAL_PRICE,

} from "Redux/Action";
import { Basket } from "Domain/Model/BasketEntry/BasketEntry";
const initialState = BasketState.getInitialState();

export const basketReducer: Reducer<TBasketState> = (state = initialState, action ) => {
    switch( action.type ) {

        case APPLICATION_BOOTSTRAPPED:
            const updatedAt = Date.now();
            if(updatedAt - state.updatedAt > 8 * 3600 * 1000) {
                return {
                    ...initialState,
                    updatedAt,
                }
            }

            return {
                ...state,
                updatedAt: Date.now(),
            }

        case ADD_TO_BASKET : {
            const basketEntry = new Basket(action.payload);
            const basketEntries: Record<string, Basket> = {};
            const keys = Object.keys(state.basketEntries);
            const count = keys.length;

            keys.forEach((id: string) => {
                basketEntries[id] = {...state.basketEntries[id]};
            });
            if (basketEntry.id in basketEntries) {
                basketEntries[basketEntry.id].amount += basketEntry.amount;
            } else {
                basketEntries[basketEntry.id] = {...basketEntry, sorting: count + 1};
            }
            return {
                ...state,
                updatedAt: Date.now(),
                basketEntries,
            };
        }

        case CHANGE_BASKET_ENTRY_TOTAL_PRICE : {
            const id = action.payload.basketEntryId;
            const basketEntries: Record<string, Basket> = {};

            Object.keys(state.basketEntries).forEach((key: string) => {
                const basketEntry = state.basketEntries[key];
                if(key === id) {
                    basketEntry.totalPrice = action.payload.totalPrice;
                }
                basketEntries[key] = { ...basketEntry };
            });

            return {
                ...state,
                updatedAt: Date.now(),
                basketEntries,
            };
        }

        case CLEAR_ORDER:
        case SEND_ORDER_SUCCESS:
            return {
                ...state,
                updatedAt: Date.now(),
                basketEntries: {

                },
            };

        case CHANGE_AMOUNT_FROM_BASKET_ENTRY:
            if(!(action.payload.id in state.basketEntries)) {
                return { ...state };
            }

            return {
                ...state,
                updatedAt: Date.now(),
                basketEntries: {
                    ...state.basketEntries,
                    [action.payload.id]: { ...state.basketEntries[action.payload.id], amount: action.payload.amount }
                },
            };

        case DELETE_BASKET_ENTRY: {
            if(!(action.payload.id in state.basketEntries)) {
                return { ...state };
            }

            const basketEntries: Record<string, Basket> = {};

            Object.keys(state.basketEntries).forEach((id: string) => {
                basketEntries[id] = {...state.basketEntries[id]};
            });
            delete basketEntries[action.payload.id];

            return {
                ...state,
                updatedAt: Date.now(),
                basketEntries,
            };
        }

        default:
            return state;
    }
};