import { IReduxStore } from "Redux/Store/IReduxStore";
import {filterObjectStore, IFilterProps, queryFilter} from "../../System";
import { Basket } from "Domain/Model/BasketEntry/BasketEntry";

export const getBasketEntries = (state: IReduxStore, filter: null|IFilterProps<Basket> = null) => {
    if(filter === null) {
        filter = queryFilter({sorting: { by: 'sorting', order: 'ASC' }}) as IFilterProps<Basket>;
    }

    return filterObjectStore(state.basket.basketEntries, filter);
};
