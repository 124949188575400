import { Meal } from "Domain/Model/Meal/Meal";

export const SEARCH_MEALS_SUCCESS = 'SEARCH_MEALS_SUCCESS';

interface IFindMealsSuccess {
    meals: Record<string, Meal>,
    request: {},
    response: {},
}

export const searchMealsSuccess = ({ meals, request, response }: IFindMealsSuccess) => ({
    type: SEARCH_MEALS_SUCCESS,
    payload: { meals },
    meta: {
        request,
        response,
    }
});
