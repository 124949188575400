import { MealCategory } from "Domain/Model/MealCategory/MealCategory";

export const FIND_ALL_MEAL_CATEGORIES_SUCCESS = 'FIND_ALL_MEAL_CATEGORIES_SUCCESS';

interface IFindMealCategoriesSuccess {
    mealCategories: Record<string, MealCategory>,
    request: {},
    response: {},
}

export const findAllMealCategoriesSuccess = ({ mealCategories, request, response }: IFindMealCategoriesSuccess) => ({
    type: FIND_ALL_MEAL_CATEGORIES_SUCCESS,
    payload: { mealCategories },
    meta: {
        request,
        response,
    }
});
