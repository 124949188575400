import { IReduxStore } from "Redux/Store/IReduxStore";
import { getServerOption } from "Redux/Query";
import { getDistanceBetweenPoints } from "ClientService";

interface IMinOrderEntry {
    key: string,
    range: number,
    value: number,
}

interface ICompanyLocation {
    address: string,
    city: string,
    country: string,
    postalCode: string,
    street: string,
    lng: number,
    lat: number,
    zoom: number
}

export const getMinOrderPrice = (state: IReduxStore) => {
    const minPriceSettings = getServerOption(state, 'minOrderSettings', []) as IMinOrderEntry[];
    if(!minPriceSettings.length) {
        return 0;
    }
    const userData = state.persist.userData;
    if(`${userData.address}` === '' || `${userData.city}` === '') {
        return 'NO_ADDRESS_GIVEN';
    }

    const companyLocation = getServerOption(state, 'companyLocation', null) as (ICompanyLocation|null);
    if(companyLocation === null) {
        return 'NO_COMPANY_ADDRESS_GIVEN';
    }

    const maxOrderRange = getServerOption(state, 'maxOrderRanger', 50) as number * 1000;
    const distance      = getDistanceBetweenPoints({ lat: userData.lat, lng: userData.lng }, { lat: companyLocation.lat, lng: companyLocation.lng });

    if(distance > maxOrderRange) {
        return 'OUT_OF_ORDER_RANGE';
    }
    let minPriceEntry: IMinOrderEntry|undefined;

    for(const priceEntry of minPriceSettings) {
        if(priceEntry.range * 1000 > distance) {
            break;
        }
        minPriceEntry = priceEntry;
    }

    return minPriceEntry?.value || 0;
}