import { IReduxStore } from "Redux/Store/IReduxStore";
import { getActiveReductions } from 'Redux/Query';
import { Meal } from "Domain/Model/Meal/Meal";
import { MealCategory } from "Domain/Model/MealCategory/MealCategory";
import { getSeconds } from "ClientService";
import moment from "moment";

export type TActiveReduction = {
    value: number,
    reductionId: null|string,
    isPercent: boolean,
    reduction: any,
};

export const getActiveReduction = (state: IReduxStore) => (price: number, meal?: Meal, category?: MealCategory, selectionOptionId?: string): number => {
    const reductions = getActiveReductions(state);
    const now = new Date();
    const currentSeconds  = getSeconds(moment(now).format('HH:mm:ss'));
    let activeReduction: TActiveReduction = {
        value: price,
        reductionId: null,
        isPercent: false,
        reduction: null,
    };

    if(!reductions.length) { return activeReduction.value; }

    const check = (reduction: any, reductionData: any) => {
        if(reduction.isPercent) {
            reductionData.value     = Math.ceil((price - (price * (reduction.price / 100))) * 10) / 10;
            reductionData.isPercent = true;
        }
        if(reductionData.value < activeReduction.value) {
            activeReduction = reductionData;
            activeReduction.reduction = reduction;
        }
    }

    for(const reduction of reductions) {
        const day = reduction.getActiveDay();

        if(
            (reduction.category !== null && reduction.category.id !== category?.id) ||
            (meal && reduction.excludeMeals.length && reduction.excludeMeals.includes(meal.id)) ||
            (!day.wholeDay && day.startAt !== null && getSeconds(`${day.startAt}`) > currentSeconds) ||
            (!day.wholeDay && day.endAt !== null && getSeconds(`${day.endAt}`) < currentSeconds)
        ) {
            continue;
        }

        const reductionData: TActiveReduction = {
            value: price,
            reductionId: null,
            isPercent: false,
            reduction: null,
        };

        reductionData.reductionId = reduction.id;
        reductionData.value       = reduction.price;


        if(selectionOptionId) {
            if(!!reduction.includeCategoryOptions.length && !reduction.includeCategoryOptions.includes(selectionOptionId)) {
                continue;
            }
            reductionData.value = reduction.price;
            check(reduction, reductionData);
            break;
        }

        check(reduction, reductionData);
    }

    return parseFloat(`${activeReduction.value}`);
};
