import { Reduction } from "Domain/Model/Reduction/Reduction";

export const SEARCH_REDUCTIONS_SUCCESS = 'SEARCH_REDUCTIONS_SUCCESS';

interface IFindReductionsSuccess {
    reductions: Record<string, Reduction>,
    request: {},
    response: {},
}

export const searchReductionsSuccess = ({ reductions, request, response }: IFindReductionsSuccess) => ({
    type: SEARCH_REDUCTIONS_SUCCESS,
    payload: { reductions },
    meta: {
        request,
        response,
    }
});
