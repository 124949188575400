import { IMealCategory, MealCategory } from "Domain/Model/MealCategory/MealCategory";
import { BaseModel } from "Domain/Model/BaseModel/BaseModel";
import { Moment} from "moment";
import isEmpty from "lodash/isEmpty";

export interface IReduction {
    id:           string,
    name:         string,
    category?:    IMealCategory,
    teaser:       string,
    startAt:      string|null,
    endAt:        string|null,
    price:        number,
    allowHoliday: boolean,
    isPercent:    boolean,
    excludeMeals: string[],
    includeCategoryOptions: string[],
    days:         IReductionDay[],
}

export interface IReductionDay {
    active:   boolean,
    wholeDay: boolean,
    startAt:  Moment|Date|string|null,
    endAt:    Moment|Date|string|null,
}

const setInitialDayStructure = (): IReductionDay[] => (['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'].map(day => ({
    day,
    active: false,
    wholeDay: true,
    startAt: null,
    endAt: null
})));

export class Reduction extends BaseModel {

    public id:           string;
    public name:         string;
    public category:     MealCategory|null;
    public teaser:       string;
    public startAt:      string|null;
    public endAt:        string|null;
    public price:        number;
    public allowHoliday: boolean;
    public isPercent:    boolean;
    public excludeMeals: string[];
    public includeCategoryOptions: string[];
    public days:         IReductionDay[];

    constructor(data: IReduction) {
        super();

        this.id           = data.id;
        this.name         = data.name;
        this.category     = !isEmpty(data.category) && data.category ? new MealCategory(data.category) : null;
        this.teaser       = data.teaser;
        this.startAt      = data.startAt;
        this.endAt        = data.endAt;
        this.price        = parseFloat(`${data.price}`);
        this.allowHoliday = data.allowHoliday === undefined ? true : data.allowHoliday;
        this.isPercent    = data.isPercent || false;
        this.excludeMeals = data.excludeMeals;
        this.includeCategoryOptions = data.includeCategoryOptions;
        this.days         = !!data.days && !!data.days.length ? data.days : setInitialDayStructure()

    }

    getActiveDay() {
        const now = new Date();
        return this.days[now.getDay() === 0 ? 6 : now.getDay() - 1];
    }

}