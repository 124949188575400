import { IReduxStore } from "Redux/Store/IReduxStore";
import { filterObjectStore, queryFilter, IFilterProps } from 'Redux/Query';
import { Reduction } from "Domain/Model/Reduction/Reduction";

export const getReductions = (state: IReduxStore, filter: null|IFilterProps<Reduction> = null) => {
    if(filter === null) {
        filter = queryFilter({sorting: { by: 'id', order: 'ASC' }}) as IFilterProps<Reduction>;
    }

    return filterObjectStore(state.reduction.reduction.entries, filter);
};
