import { FIND_MEAL_CATEGORY_REQUEST, findMealCategorySuccess, findMealCategoryFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const findMealCategoryEpic:TEpic = (action$, store$, { MealCategoryRepository }) => {
    return action$.pipe(
        ofType(FIND_MEAL_CATEGORY_REQUEST),
        mergeMap( action =>{
            const { id } = action.payload;

            if(!id.trim()) {
                return EMPTY;
            }

            return from(
                MealCategoryRepository.findById( id )
                    .then(ingredient => findMealCategorySuccess(ingredient))
                    .catch(error => findMealCategoryFailure(error)),
            );
        }),
    );
}
