import { FIND_MEALS_REQUEST, findMealsSuccess, findMealsFailure } from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const findMealsEpic: TEpic = (action$, store$, { MealRepository }) => {
    return action$.pipe(
        ofType( FIND_MEALS_REQUEST ),
        mergeMap(action => from(
            MealRepository.findAll(action.payload)
                .then(({ meals, meta }) => findMealsSuccess({ meals, response: meta, request: action}))
                .catch((error: Error) => findMealsFailure(error)),
        )),
    );
}
