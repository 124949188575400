import { FIND_REDUCTION_REQUEST, findReductionSuccess, findReductionFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const findReductionEpic:TEpic = (action$, store$, { ReductionRepository }) => {
    return action$.pipe(
        ofType(FIND_REDUCTION_REQUEST),
        mergeMap( action =>{
            const { id } = action.payload;

            if(!id.trim()) {
                return EMPTY;
            }

            return from(
                ReductionRepository.findById( id )
                    .then(reductions => findReductionSuccess(reductions))
                    .catch(error => findReductionFailure(error)),
            );
        }),
    );
}
