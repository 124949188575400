import { IFilterSettings } from "Domain/Repository/BaseRepository/BaseRepository";

export const FIND_MEAL_CATEGORIES_REQUEST = 'FIND_MEAL_CATEGORIES_REQUEST';

export const findMealCategoriesRequest = ({
     filter = [],
     sorting = { field: 'id', order: 'ASC' },
     pagination = { page: 1, take: 50 },
 }: IFilterSettings ) => ({
    type: FIND_MEAL_CATEGORIES_REQUEST,
    payload: { filter, sorting, pagination },
    meta: {
        sorting,
    }
});
