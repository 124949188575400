import { IReduxStore } from "Redux/Store/IReduxStore";
import { filterObjectStore, queryFilter, IFilterProps } from 'Redux/Query';
import { Ingredient }   from "Domain/Model/Ingredient/Ingredient";

export const getIngredientsBySearch = (state: IReduxStore, filter: null|IFilterProps<Ingredient> = null) => {
    if(filter === null) {
        filter = queryFilter({sorting: { by: 'name', order: 'ASC' }}) as IFilterProps<Ingredient>;
    }

    return filterObjectStore(state.ingredient.searchEntries, filter);
};
