import { IReduxStore } from "Redux/Store/IReduxStore";
import {
    FIND_MEAL_REQUEST,
    FIND_MEALS_REQUEST,
    SEARCH_MEALS_REQUEST,
    CREATE_MEAL_REQUEST,
    UPDATE_MEAL_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isMealLoading = (state: IReduxStore): boolean =>
    ArrayContains(state.meal.openRequests, [
        FIND_MEAL_REQUEST,
        FIND_MEALS_REQUEST,
        SEARCH_MEALS_REQUEST,
        CREATE_MEAL_REQUEST,
        UPDATE_MEAL_REQUEST,
    ]);