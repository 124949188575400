import { IReduxStore } from "Redux/Store/IReduxStore";
import {
    FIND_REDUCTION_REQUEST,
    FIND_REDUCTIONS_REQUEST,
    SEARCH_REDUCTIONS_REQUEST,
    CREATE_REDUCTION_REQUEST,
    UPDATE_REDUCTION_REQUEST,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isReductionLoading = (state: IReduxStore): boolean =>
    ArrayContains(state.reduction.openRequests, [
        FIND_REDUCTION_REQUEST,
        FIND_REDUCTIONS_REQUEST,
        SEARCH_REDUCTIONS_REQUEST,
        CREATE_REDUCTION_REQUEST,
        UPDATE_REDUCTION_REQUEST,
    ]);