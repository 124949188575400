import { globalReducer }       from "./Global/GlobalReducer";
import { userReducer }         from "./User/UserReducer";
import { allergenReducer }     from "./Allergen/AllergenReducer";
import { ingredientReducer }   from "./Ingredient/IngredientReducer";
import { mealCategoryReducer } from "./MealCategory/MealCategoryReducer";
import { mealReducer }         from "./Meal/MealReducer";
import { reductionReducer }    from "./Reduction/ReductionReducer";
import { persistReducer }      from "./Persist/PersistReducer";
import { basketReducer }       from "./Basket/BasketReducer";
import { orderReducer }       from "./Order/OrderReducer";

const reducer = {
    global:       globalReducer,
    user:         userReducer,
    allergen:     allergenReducer,
    ingredient:   ingredientReducer,
    mealCategory: mealCategoryReducer,
    meal:         mealReducer,
    reduction:    reductionReducer,
    persist:      persistReducer,
    basket:       basketReducer,
    order:        orderReducer,
};

export default reducer;