import { FIND_ALLERGEN_REQUEST, findAllergenSuccess, findAllergenFailure} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const findAllergenEpic:TEpic = (action$, store$, { AllergenRepository }) => {
    return action$.pipe(
        ofType(FIND_ALLERGEN_REQUEST),
        mergeMap( action =>{
            const { id } = action.payload;

            if(!id.trim()) {
                return EMPTY;
            }

            return from(
                AllergenRepository.findById( id )
                    .then(allergen => findAllergenSuccess(allergen))
                    .catch(error => findAllergenFailure(error)),
            );
        }),
    );
}
