import { IReduxStore } from "Redux/Store/IReduxStore";
import {
    CREATE_ALLERGEN_FAILURE,
    UPDATE_ALLERGEN_FAILURE,
    FIND_ALLERGEN_FAILURE,
    FIND_ALLERGENE_FAILURE,
    SEARCH_ALLERGENE_FAILURE,
} from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isAllergenFailed = (state: IReduxStore): boolean => ArrayContains(state.allergen.failedRequests, [
    CREATE_ALLERGEN_FAILURE,
    UPDATE_ALLERGEN_FAILURE,
    FIND_ALLERGEN_FAILURE,
    FIND_ALLERGENE_FAILURE,
    SEARCH_ALLERGENE_FAILURE,
]);