import {
    UPDATE_ORDER_REQUEST,
    updateOrderSuccess,
    updateOrderFailure,
    showSuccessNotification
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { TEpic } from "Redux/Config";

export const updateOrderEpic: TEpic = (action$, store$, { OrderRepository }) => {
    return action$.pipe(
        ofType(UPDATE_ORDER_REQUEST),
        mergeMap(action => from(OrderRepository.update( action.payload.id, action.payload.updateData ))
            .pipe(
                mergeMap((orders) => {
                    return from([
                        showSuccessNotification(`Die Speicherung der Bestellung war erfolgreich.`),
                        updateOrderSuccess(orders),
                    ]);
                }),
                catchError( error => {
                    return from([
                        updateOrderFailure(error)
                    ])
                })
            )
        ),
    );
}
