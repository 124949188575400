import { applicationBootstrapped } from 'Redux/Action';
import { rehydrateStore, Store }   from 'Redux/Config';

async function hydrateStore() {
    const state: any = Store.getState();
    if (state._persist && state._persist.rehydrated) {
        if (state.hasOwnProperty('global') && state.global.isInitialized) {
            // here can be check for reload or other thinks
            return false;
        }
    }
    await rehydrateStore(Store);
    Store.dispatch(applicationBootstrapped());
    return true;
}

export default hydrateStore;