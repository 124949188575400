import React from 'react';
import FooterStyle from './Footer.style';
import { version } from 'AppConfig/Config';
import Link from "Component/Common/Link/Link";
import { linkHandler} from "ClientService";
import { connect, MapStateToProps } from "react-redux";
import { IReduxStore } from "Redux/Store/IReduxStore";
import {getAllergene, getServerOption} from "Redux/Query";
import {Col, Row} from "Component/Common/Grid/Grid";
import Button from "Component/Common/Button/Button";
import Icon from "Component/Common/Icon/Icon";
import PopOver from "Component/Container/PopOver/PopOver";
import { Allergen } from "Domain/Model/Allergen/Allergen";


interface IStateProps {
    getServerOption: (key: string, defaultValue?: unknown) => unknown,
    allergens: Allergen[],
}

type TState = {
    showPopOver: boolean,
}

type TProps = IStateProps ;


class Footer extends React.Component<TProps, TState> {

    state: TState = {
        showPopOver: false,
    }

    handleOnShowPopOver = () => {
        this.setState(() => ({ showPopOver: true }))
    }
    handleOnClosePopOver = () => {
        this.setState(() => ({ showPopOver: false }))
    }

    render() {
        const year = new Date().getFullYear();

        return (
            <FooterStyle>
                <Link className="footerLink" externalLink={linkHandler.get('termsOfAgreements')}>Datenschutz- und Nutzungsbedingungen</Link>
                <Link className="footerLink" externalLink={linkHandler.get('imprint')}>Impressum</Link>
                <Link className="footerLink" externalLink={linkHandler.get('termsOfUse')}>AGB</Link>
                <span className="footerLink" onClick={ this.handleOnShowPopOver }><span>Allergene</span></span>
                { this.state.showPopOver ?
                    <PopOver
                        className="popOver"
                        visible={ true }
                        onClose={ this.handleOnClosePopOver }
                        headline={ <h2 className="textPrimaryColor bold">Allergene</h2> }
                        actions={ (
                            <Row>
                                <Col xs={  8 } sm={ 16 } md={ 18 } lg={ 20 } />
                                <Col xs={ 16 } sm={ 8 } md={ 6 } lg={ 4 } style={{ flexDirection: 'row-reverse' }}>
                                    <Button onClick={ this.handleOnClosePopOver } ><Icon type="close"/> Schließen</Button>
                                </Col>
                            </Row>
                        ) }
                    >
                        <Row>
                            { this.props.allergens.map(allergen => (<Col xs={ 24 } sm={ 12 } md={ 6 } key={allergen.id}><strong>{ allergen.key }</strong> <i>{ allergen.name }</i></Col>))}
                        </Row>
                    </PopOver> : null
                }

                &copy; {year}, { this.props.getServerOption('siteName', 'Deinen Lieblings-Lieferservice') } | v{version}
            </FooterStyle>
        );
    }

};


const mapStateToProps: MapStateToProps<IStateProps, {}, IReduxStore> = (state) => {
    return {
        getServerOption: (key: string, defaultValue: unknown) => {
            return getServerOption(state, key, defaultValue)
        },
        allergens: getAllergene(state),
    };
}

export default connect(mapStateToProps)(Footer);
