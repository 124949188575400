import { Ingredient } from "Domain/Model/Ingredient/Ingredient";

export const SEARCH_INGREDIENTS_SUCCESS = 'SEARCH_INGREDIENTS_SUCCESS';

interface IFindIngredientsSuccess {
    ingredients: Record<string, Ingredient>,
    request: {},
    response: {},
}

export const searchIngredientsSuccess = ({ ingredients, request, response }: IFindIngredientsSuccess) => ({
    type: SEARCH_INGREDIENTS_SUCCESS,
    payload: { ingredients },
    meta: {
        request,
        response,
    }
});
