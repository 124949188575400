import { combineEpics } from 'redux-observable';

import { createMealCategoryEpic }    from './CreateMealCategory/CreateMealCategoryEpic';
import { deleteMealCategoryEpic }    from './DeleteMealCategory/DeleteMealCategoryEpic';
import { findMealCategoriesEpic }    from './FindMealCategories/FindMealCategoriesEpic';
import { findMealCategoryEpic }      from './FindMealCategory/FindMealCategoryEpic';
import { searchMealCategoriesEpic }  from './SearchMealCategories/SearchMealCategoriesEpic';
import { updateMealCategoryEpic }    from './UpdateMealCategory/UpdateMealCategoryEpic';
import { findAllMealCategoriesEpic } from './FindAllMealCategories/FindAllMealCategoriesEpic';


export default combineEpics(
    createMealCategoryEpic,
    deleteMealCategoryEpic,
    findMealCategoriesEpic,
    findMealCategoryEpic,
    searchMealCategoriesEpic,
    updateMealCategoryEpic,
    findAllMealCategoriesEpic,
);
